<div id="progress_bar_wrapper">
    <p class="notice">{{ this.getNoticeMessage() }}</p>
    <div id="progress_bar_and_cancel">
        <div class="progress_bar">
            <div
                class="completion_bar"
                [ngStyle]="{ width: this.completionWidth() }"
            ></div>
        </div>
        <img
            src="assets/icons/exit.svg"
            class="cancellation"
            (click)="this.cancelUpload()"
        />
    </div>
</div>

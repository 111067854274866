export const month_list = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const data_usage_list = [
  {
    label: "National",
    value: "national",
  },
  {
    label: "Subnational",
    value: "sub_national",
  },
  {
    label: "National & Subnational",
    value: "national_and_sub",
  },
];

export const panel_list = [
  { label: "DKM", value: "DKM" },
  { label: "PharmaScope", value: "PharmaScope" },
  { label: "PharmaScope_sub", value: "PharmaScope_sub" },
  { label: "EHN", value: "EHN" },
  { label: "SO_PH", value: "SO_PH" },
  { label: "SI", value: "SI" },
  { label: "XtrendPTs", value: "XtrendPTs" },
  { label: "NID Non Retail", value: "NID Non Retail" },
  { label: "NID Retail and DPC", value: "NID Retail and DPC" },
  { label: "RID", value: "RID" },
  { label: "TID", value: "TID" },
  { label: "FarminForm", value: "FarminForm" },
  { label: "XtrendNL", value: "XtrendNL" },
  { label: "NPA", value: "NPA" },
  { label: "EMH", value: "EMH" },
  { label: "Sell Out", value: "Sell Out" },
  { label: "Sellin", value: "Sellin" },
  { label: "Non Retail", value: "Non Retail" },
  { label: "National Non Retail", value: "National Non Retail" },
  { label: "National Retail", value: "National Retail" },
  { label: "Subnational Switzerland", value: "Subnational Switzerland" },
  { label: "GPI", value: "GPI" },
  { label: "Territorial_Data", value: "Territorial_Data" },
  { label: "LMPB", value: "LMPB" },
  { label: "LMPL", value: "LMPL" },
  { label: "OAD", value: "OAD" },
  { label: "V03G1", value: "V03G1" },
  { label: "Belgium_Respi", value: "Belgium_Respi" },
  { label: "HOSP", value: "HOSP" },
  { label: "Sellout APO Sellout Hapo", value: "Sellout APO Sellout Hapo" },
  { label: "APO HAPO", value: "APO HAPO" },
  { label: "NSP", value: "NSP" },
  { label: "CD&H", value: "CD&H" },
  { label: "JPM", value: "JPM" },
  { label: "CHPA", value: "CHPA" },
  { label: "HPAI XBPI", value: "HPAI XBPI" },
  { label: "ALHP", value: "ALHP" },
  { label: "NHS_Wales", value: "NHS_Wales" },
  { label: "NHS_Scotland", value: "NHS_Scotland" },
  { label: "NHS_England", value: "NHS_England" },
  { label: "NHS_Northen_Ireland", value: "NHS_Northen_Ireland" },
  { label: "SDM", value: "SDM" },
  { label: "Xponent_Standard", value: "Xponent_Standard" },
  { label: "IQVIA Xponent Spécialiste", value: "IQVIA Xponent Spécialiste" },
  { label: "Xponent_EDS", value: "Xponent_EDS" },
  { label: "IQVIA Xponent Hebdo", value: "IQVIA Xponent Hebdo" },
  { label: "IQVIA HCA", value: "IQVIA HCA" },
  { label: "GPlus_Officine", value: "GPlus_Officine" },
  { label: "GPlus_Hopital", value: "GPlus_Hopital" },
  { label: "WHS_BG_N", value: "WHS_BG_N" },
  { label: "WHS_BG_S", value: "WHS_BG_S" },
  { label: "WHS_HR_N", value: "WHS_HR_N" },
  { label: "WHS_HR_S", value: "WHS_HR_S" },
  { label: "WHS_RS_N", value: "WHS_RS_N" },
  { label: "WHS_RS_S", value: "WHS_RS_S" },
  { label: "WHS_SI_N", value: "WHS_SI_N" },
  { label: "WHS_SI_S", value: "WHS_SI_S" },
  { label: "HIF_EE_N", value: "HIF_EE_N" },
  { label: "WHS_EE_N", value: "WHS_EE_N" },
  { label: "HIF_EE_S", value: "HIF_EE_S" },
  { label: "WHS_EE_S", value: "WHS_EE_S" },
  { label: "WHS_LV_N", value: "WHS_LV_N" },
  { label: "WHS_LV_S", value: "WHS_LV_S" },
  { label: "Softdent_LT_N", value: "Softdent_LT_N" },
  { label: "Softdent_LT_S", value: "Softdent_LT_S" },
  { label: "WHS_CZ_N", value: "WHS_CZ_N" },
  { label: "WHS_CZ_S", value: "WHS_CZ_S" },
  { label: "PCY_CZ_N", value: "PCY_CZ_N" },
  { label: "PCY_CZ_S", value: "PCY_CZ_S" },
  { label: "SUKL_CZ_N", value: "SUKL_CZ_N" },
  { label: "DTP_CZ_S", value: "DTP_CZ_S" },
  { label: "WHS_HU_N", value: "WHS_HU_N" },
  { label: "NHIF_HU_N", value: "NHIF_HU_N" },
  { label: "NHIF_HU_S", value: "NHIF_HU_S" },
  { label: "WHS_HU_S", value: "WHS_HU_S" },
  { label: "WHS_SK_N", value: "WHS_SK_N" },
  { label: "WHS_SK_S", value: "WHS_SK_S" },
  { label: "PCY_SK_N", value: "PCY_SK_N" },
  { label: "NCZI_SK_S", value: "NCZI_SK_S" },
  { label: "DDS_SK_S", value: "DDS_SK_S" },
  { label: "WHS_PL_N", value: "WHS_PL_N" },
  { label: "Sell-out National", value: "Sell-out National" },
  { label: "WHS_PL_S", value: "WHS_PL_S" },
  {
    label: "WHS_RO_N Retail Pharmacies National",
    value: "WHS_RO_N Retail Pharmacies National",
  },
  {
    label: "Retail Pharmacie Subnational",
    value: "Retail Pharmacie Subnational",
  },
  { label: "WHS_RO_S", value: "WHS_RO_S" },
].sort((a, b) => {
  return a.label.localeCompare(b.label);
});

export const country_list = [
  { label: "Afghanistan", value: "af" },
  { label: "Åland Islands", value: "ax" },
  { label: "Albania", value: "al" },
  { label: "Algeria", value: "dz" },
  { label: "American Samoa", value: "as" },
  { label: "Andorra", value: "ad" },
  { label: "Angola", value: "ao" },
  { label: "Anguilla", value: "ai" },
  { label: "Antarctica", value: "aq" },
  { label: "Antigua and Barbuda", value: "ag" },
  { label: "Argentina", value: "ar" },
  { label: "Armenia", value: "am" },
  { label: "Aruba", value: "aw" },
  { label: "Australia", value: "au" },
  { label: "Austria", value: "at" },
  { label: "Azerbaijan", value: "az" },
  { label: "Bahamas", value: "bs" },
  { label: "Bahrain", value: "bh" },
  { label: "Bangladesh", value: "bd" },
  { label: "Barbados", value: "bb" },
  { label: "Belarus", value: "by" },
  { label: "Belgium", value: "be" },
  { label: "Belize", value: "bz" },
  { label: "Benin", value: "bj" },
  { label: "Bermuda", value: "bm" },
  { label: "Bhutan", value: "bt" },
  { label: "Bolivia (Plurinational State of)", value: "bo" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "bq" },
  { label: "Bosnia and Herzegovina", value: "ba" },
  { label: "Botswana", value: "bw" },
  { label: "Bouvet Island", value: "bv" },
  { label: "Brazil", value: "br" },
  { label: "British Indian Ocean Territory", value: "io" },
  { label: "Brunei Darussalam", value: "bn" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burkina Faso", value: "bf" },
  { label: "Burundi", value: "bi" },
  { label: "Cabo Verde", value: "cv" },
  { label: "Cambodia", value: "kh" },
  { label: "Cameroon", value: "cm" },
  { label: "Canada", value: "ca" },
  { label: "Cayman Islands", value: "ky" },
  { label: "Central African Republic", value: "cf" },
  { label: "Chad", value: "td" },
  { label: "Chile", value: "cl" },
  { label: "China", value: "cn" },
  { label: "Christmas Island", value: "cx" },
  { label: "Cocos (Keeling) Islands", value: "cc" },
  { label: "Colombia", value: "co" },
  { label: "Comoros", value: "km" },
  { label: "Congo", value: "cg" },
  { label: "Congo (Democratic Republic of the)", value: "cd" },
  { label: "Cook Islands", value: "ck" },
  { label: "Costa Rica", value: "cr" },
  { label: "Croatia", value: "hr" },
  { label: "Cuba", value: "cu" },
  { label: "Curaçao", value: "cw" },
  { label: "Cyprus", value: "cy" },
  { label: "Czech Republic", value: "cz" },
  { label: "Denmark", value: "dk" },
  { label: "Djibouti", value: "dj" },
  { label: "Dominica", value: "dm" },
  { label: "Dominican Republic", value: "do" },
  { label: "Ecuador", value: "ec" },
  { label: "Egypt", value: "eg" },
  { label: "El Salvador", value: "sv" },
  { label: "Equatorial Guinea", value: "gq" },
  { label: "Eritrea", value: "er" },
  { label: "Estonia", value: "ee" },
  { label: "Eswatini", value: "sz" },
  { label: "Ethiopia", value: "et" },
  { label: "Falkland Islands (Malvinas)", value: "fk" },
  { label: "Faroe Islands", value: "fo" },
  { label: "Fiji", value: "fj" },
  { label: "Finland", value: "fi" },
  { label: "France", value: "fr" },
  { label: "French Guiana", value: "gf" },
  { label: "French Polynesia", value: "pf" },
  { label: "French Southern Territories", value: "tf" },
  { label: "Gabon", value: "ga" },
  { label: "Gambia", value: "gm" },
  { label: "Georgia", value: "ge" },
  { label: "Germany", value: "de" },
  { label: "Ghana", value: "gh" },
  { label: "Gibraltar", value: "gi" },
  { label: "Greece", value: "gr" },
  { label: "Greenland", value: "gl" },
  { label: "Grenada", value: "gd" },
  { label: "Guadeloupe", value: "gp" },
  { label: "Guam", value: "gu" },
  { label: "Guatemala", value: "gt" },
  { label: "Guernsey", value: "gg" },
  { label: "Guinea", value: "gn" },
  { label: "Guinea-Bissau", value: "gw" },
  { label: "Guyana", value: "gy" },
  { label: "Haiti", value: "ht" },
  { label: "Heard Island and McDonald Islands", value: "hm" },
  { label: "Holy See", value: "va" },
  { label: "Honduras", value: "hn" },
  { label: "Hong Kong", value: "hk" },
  { label: "Hungary", value: "hu" },
  { label: "Iceland", value: "is" },
  { label: "India", value: "in" },
  { label: "Indonesia", value: "id" },
  { label: "Iran (Islamic Republic of)", value: "ir" },
  { label: "Iraq", value: "iq" },
  { label: "Ireland", value: "ie" },
  { label: "Isle of Man", value: "im" },
  { label: "Israel", value: "il" },
  { label: "Italy", value: "it" },
  { label: "Jamaica", value: "jm" },
  { label: "Japan", value: "jp" },
  { label: "Jersey", value: "je" },
  { label: "Jordan", value: "jo" },
  { label: "Kazakhstan", value: "kz" },
  { label: "Kenya", value: "ke" },
  { label: "Kiribati", value: "ki" },
  { label: "Korea (Democratic People's Republic of)", value: "kp" },
  { label: "Korea (Republic of)", value: "kr" },
  { label: "Kuwait", value: "kw" },
  { label: "Kyrgyzstan", value: "kg" },
  { label: "Lao People's Democratic Republic", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Lebanon", value: "lb" },
  { label: "Lesotho", value: "ls" },
  { label: "Liberia", value: "lr" },
  { label: "Libya", value: "ly" },
  { label: "Liechtenstein", value: "li" },
  { label: "Lithuania", value: "lt" },
  { label: "Luxembourg", value: "lu" },
  { label: "Macao", value: "mo" },
  { label: "Madagascar", value: "mg" },
  { label: "Malawi", value: "mw" },
  { label: "Malaysia", value: "my" },
  { label: "Maldives", value: "mv" },
  { label: "Mali", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Marshall Islands", value: "mh" },
  { label: "Martinique", value: "mq" },
  { label: "Mauritania", value: "mr" },
  { label: "Mauritius", value: "mu" },
  { label: "Mayotte", value: "yt" },
  { label: "Mexico", value: "mx" },
  { label: "Micronesia (Federated States of)", value: "fm" },
  { label: "Moldova (Republic of)", value: "md" },
  { label: "Monaco", value: "mc" },
  { label: "Mongolia", value: "mn" },
  { label: "Montenegro", value: "me" },
  { label: "Montserrat", value: "ms" },
  { label: "Morocco", value: "ma" },
  { label: "Mozambique", value: "mz" },
  { label: "Myanmar", value: "mm" },
  { label: "Namibia", value: "na" },
  { label: "Nauru", value: "nr" },
  { label: "Nepal", value: "np" },
  { label: "Netherlands", value: "nl" },
  { label: "New Caledonia", value: "nc" },
  { label: "New Zealand", value: "nz" },
  { label: "Nicaragua", value: "ni" },
  { label: "Niger", value: "ne" },
  { label: "Nigeria", value: "ng" },
  { label: "Niue", value: "nu" },
  { label: "Norfolk Island", value: "nf" },
  { label: "North Macedonia", value: "mk" },
  { label: "Northern Mariana Islands", value: "mp" },
  { label: "Norway", value: "no" },
  { label: "Oman", value: "om" },
  { label: "Pakistan", value: "pk" },
  { label: "Palau", value: "pw" },
  { label: "Palestine, State of", value: "ps" },
  { label: "Panama", value: "pa" },
  { label: "Papua New Guinea", value: "pg" },
  { label: "Paraguay", value: "py" },
  { label: "Peru", value: "pe" },
  { label: "Philippines", value: "ph" },
  { label: "Pitcairn", value: "pn" },
  { label: "Poland", value: "pl" },
  { label: "Portugal", value: "pt" },
  { label: "Puerto Rico", value: "pr" },
  { label: "Qatar", value: "qa" },
  { label: "Réunion", value: "re" },
  { label: "Romania", value: "ro" },
  { label: "Russian Federation", value: "ru" },
  { label: "Rwanda", value: "rw" },
  { label: "Saint Barthélemy", value: "bl" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "sh" },
  { label: "Saint Kitts and Nevis", value: "kn" },
  { label: "Saint Lucia", value: "lc" },
  { label: "Saint Martin (French part)", value: "mf" },
  { label: "Saint Pierre and Miquelon", value: "pm" },
  { label: "Saint Vincent and the Grenadines", value: "vc" },
  { label: "Samoa", value: "ws" },
  { label: "San Marino", value: "sm" },
  { label: "Sao Tome and Principe", value: "st" },
  { label: "Saudi Arabia", value: "sa" },
  { label: "Senegal", value: "sn" },
  { label: "Serbia", value: "rs" },
  { label: "Seychelles", value: "sc" },
  { label: "Sierra Leone", value: "sl" },
  { label: "Singapore", value: "sg" },
  { label: "Sint Maarten (Dutch part)", value: "sx" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "si" },
  { label: "Solomon Islands", value: "sb" },
  { label: "Somalia", value: "so" },
  { label: "South Africa", value: "za" },
  { label: "South Georgia and the South Sandwich Islands", value: "gs" },
  { label: "South Sudan", value: "ss" },
  { label: "Spain", value: "es" },
  { label: "Sri Lanka", value: "lk" },
  { label: "Sudan", value: "sd" },
  { label: "Suriname", value: "sr" },
  { label: "Svalbard and Jan Mayen", value: "sj" },
  { label: "Sweden", value: "se" },
  { label: "Switzerland", value: "ch" },
  { label: "Syrian Arab Republic", value: "sy" },
  { label: "Taiwan", value: "tw" },
  { label: "Tajikistan", value: "tj" },
  { label: "Tanzania, United Republic of", value: "tz" },
  { label: "Thailand", value: "th" },
  { label: "Timor-Leste", value: "tl" },
  { label: "Togo", value: "tg" },
  { label: "Tokelau", value: "tk" },
  { label: "Tonga", value: "to" },
  { label: "Trinidad and Tobago", value: "tt" },
  { label: "Tunisia", value: "tn" },
  { label: "Turkey", value: "tr" },
  { label: "Turkmenistan", value: "tm" },
  { label: "Turks and Caicos Islands", value: "tc" },
  { label: "Tuvalu", value: "tv" },
  { label: "Uganda", value: "ug" },
  { label: "Ukraine", value: "ua" },
  { label: "United Arab Emirates", value: "ae" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "gb",
  },
  { label: "United States of America", value: "us" },
  { label: "Uruguay", value: "uy" },
  { label: "Uzbekistan", value: "uz" },
  { label: "Vanuatu", value: "vu" },
  { label: "Venezuela (Bolivarian Republic of)", value: "ve" },
  { label: "Viet Nam", value: "vn" },
  { label: "Virgin Islands (British)", value: "vg" },
  { label: "Virgin Islands (U.S.)", value: "vi" },
  { label: "Wallis and Futuna", value: "wf" },
  { label: "Western Sahara", value: "eh" },
  { label: "Yemen", value: "ye" },
  { label: "Zambia", value: "zm" },
  { label: "Zimbabwe", value: "zw" },
];

export const data_type_list = [
  { label: "Market Sales", value: "market_sales" },
  { label: "Prescription", value: "prescription" },
  { label: "Longitudinal Patient Data", value: "longitudinal" },
  { label: "Non-Longitudinal Patient Data", value: "non_longitudinal" },
  { label: "Medical Audit", value: "medical_audit" },
  { label: "Customer data", value: "customer_data" },
  { label: "Claims", value: "claims" },
  { label: "Epidemiology", value: "epidemiology" },
];

export const granularity_list = [
  { label: "Country", value: "national" },
  { label: "Region", value: "sub_national" },
  { label: "Province/State", value: "province_state" },
  { label: "Territory", value: "territory" },
  { label: "Bricks", value: "brick" },
  { label: "Micro-Bricks", value: "micro_bricks" },
];

export const channel_list = [
  { label: "Retail Sell-in", value: "retail" },
  { label: "Retail Sell-out", value: "retail_out" },
  { label: "Non-Retail Sell-in", value: "non_retail" },
  { label: "Non-Retail Sell-out", value: "non_retail_out" },
  { label: "Combined Sell-in", value: "combined_sell_in" },
  { label: "Combined Sell-out", value: "combined_sell_out" },
  { label: "Retail Sell-in/Sell-out", value: "retail_sell_in_sell_out" },
  {
    label: "Non Retail Sell-in/Sell-out",
    value: "non_retail_sell_in_sell_out",
  },
  { label: "Combined Sell-in/Sell-out", value: "combined_sell_in_sell_out" },
];

export const data_vendor_list = [
  { label: "ACNIELSEN", value: "ACNIELSEN" },
  { label: "ACROSS HEALTH", value: "ACROSS HEALTH" },
  { label: "ADLIVA", value: "ADLIVA" },
  { label: "AL MITHALIA", value: "AL MITHALIA" },
  { label: "ALPHARMA", value: "ALPHARMA" },
  { label: "AMA", value: "AMA" },
  { label: "AMS", value: "AMS" },
  { label: "ANALYTICAL WIZARDS", value: "ANALYTICAL WIZARDS" },
  { label: "APLLIED SERVICES", value: "APLLIED SERVICES" },
  { label: "AXON", value: "AXON" },
  { label: "AXTRIA", value: "AXTRIA" },
  { label: "BGM DATA LIMITED", value: "BGM DATA LIMITED" },
  { label: "BMT", value: "BMT" },
  { label: "BUZZBACK LLC", value: "BUZZBACK LLC" },
  { label: "BYPRICE", value: "BYPRICE" },
  { label: "CAPGEMINI", value: "CAPGEMINI" },
  { label: "CEGEDIM", value: "CEGEDIM" },
  { label: "CENTRIX", value: "CENTRIX" },
  { label: "CHAUCER GROUP", value: "CHAUCER GROUP" },
  { label: "CIBTMR", value: "CIBTMR" },
  { label: "CLARIVATE", value: "CLARIVATE" },
  { label: "CLOSE-UP", value: "CLOSE-UP" },
  { label: "COBBS CREEK HEALTHCARE", value: "COBBS CREEK HEALTHCARE" },
  { label: "COREZETTA", value: "COREZETTA" },
  { label: "CPA", value: "CPA" },
  { label: "CTS", value: "CTS" },
  { label: "D2 STRATEGY", value: "D2 STRATEGY" },
  { label: "DATABRICKS", value: "DATABRICKS" },
  { label: "DHC", value: "DHC" },
  { label: "DKSH", value: "DKSH" },
  { label: "DLI MI", value: "DLI MI" },
  { label: "DMD", value: "DMD" },
  { label: "DOXA", value: "DOXA" },
  { label: "DR DECISION RESOURCES", value: "DR DECISION RESOURCES" },
  { label: "DRG", value: "DRG" },
  { label: "DUCKERFRONTIER", value: "DUCKERFRONTIER" },
  { label: "EDGE ", value: "EDGE " },
  { label: "ENCISE", value: "ENCISE" },
  { label: "EPHMRA", value: "EPHMRA" },
  {
    label: "EVERSANA LIFE SCIENCE SERVICES",
    value: "EVERSANA LIFE SCIENCE SERVICES",
  },
  { label: "FARMINFORM", value: "FARMINFORM" },
  { label: "FDB", value: "FDB" },
  { label: "FECUND PROJECTS", value: "FECUND PROJECTS" },
  { label: "FITCH SOLUTIONS GROUP", value: "FITCH SOLUTIONS GROUP" },
  { label: "FLATIRON HEALTH", value: "FLATIRON HEALTH" },
  { label: "GENPACT", value: "GENPACT" },
  { label: "GLOBALDATA", value: "GLOBALDATA" },
  { label: "GROUP H", value: "GROUP H" },
  { label: "HEADWAY", value: "HEADWAY" },
  { label: "HEALTHACE", value: "HEALTHACE" },
  { label: "HMR", value: "HMR" },
  { label: "IQVIA", value: "IQVIA" },
  {
    label: "IMPERIAL COLLEGE HEALTH PARTNERS",
    value: "IMPERIAL COLLEGE HEALTH PARTNERS",
  },
  { label: "INFOBASIC", value: "INFOBASIC" },
  {
    label: "INFORMATION RESOURCES (UK) LTD",
    value: "INFORMATION RESOURCES (UK) LTD",
  },
  { label: "INSIGHT HEALTH", value: "INSIGHT HEALTH" },
  { label: "INTAGE", value: "INTAGE" },
  { label: "INTELLIX", value: "INTELLIX" },
  { label: "INTRINSIQ", value: "INTRINSIQ" },
  { label: "INVENTIV", value: "INVENTIV" },
  { label: "IPSOS", value: "IPSOS" },
  { label: "IQVIA", value: "IQVIA" },
  { label: "IRI", value: "IRI" },
  { label: "JMDC", value: "JMDC" },
  { label: "JUDY WILLITS CONSULTING", value: "JUDY WILLITS CONSULTING" },
  { label: "KANTAR", value: "KANTAR" },
  { label: "LECA SOLUTIONS", value: "LECA SOLUTIONS" },
  { label: "LEK CONSULTING", value: "LEK CONSULTING" },
  { label: "LUMINAS", value: "LUMINAS" },
  { label: "M3", value: "M3" },
  { label: "MARKET LOGIC SOFTWARE AG", value: "MARKET LOGIC SOFTWARE AG" },
  { label: "MASLANSKY AND PARTNERS", value: "MASLANSKY AND PARTNERS" },
  { label: "MEDICUBE ", value: "MEDICUBE " },
  { label: "MERKLE", value: "MERKLE" },
  { label: "MMIT", value: "MMIT" },
  { label: "NCONTROL", value: "NCONTROL" },
  { label: "NICHOLAS HALL", value: "NICHOLAS HALL" },
  { label: "NORDIMED", value: "NORDIMED" },
  { label: "OMNICOM GROUP", value: "OMNICOM GROUP" },
  {
    label: "OPEN HEALTH COMMUNICATIONS LLP",
    value: "OPEN HEALTH COMMUNICATIONS LLP",
  },
  { label: "P4 ANALYTICS", value: "P4 ANALYTICS" },
  {
    label: "PEOPLE INTELLIGENCE PARTNERSHIP",
    value: "PEOPLE INTELLIGENCE PARTNERSHIP",
  },
  { label: "PEX", value: "PEX" },
  { label: "PHARBERS", value: "PHARBERS" },
  {
    label: "PHARMA BUSINESS REVIEW LTD",
    value: "PHARMA BUSINESS REVIEW LTD",
  },
  { label: "PHARMAACE", value: "PHARMAACE" },
  { label: "PHARMAFAKT", value: "PHARMAFAKT" },
  { label: "PHARMANALYTICA", value: "PHARMANALYTICA" },
  { label: "PHARMAREVIEW", value: "PHARMAREVIEW" },
  { label: "POPPIN JAY INC", value: "POPPIN JAY INC" },
  { label: "PROGNOS", value: "PROGNOS" },
  { label: "PROXIMA RESEARCH", value: "PROXIMA RESEARCH" },
  { label: "RNC", value: "RNC" },
  { label: "RX HEALTH INFO SYSTEMS", value: "RX HEALTH INFO SYSTEMS" },
  { label: "SANISPHERE", value: "SANISPHERE" },
  { label: "SERMO", value: "SERMO" },
  { label: "SINOHEALTH", value: "SINOHEALTH" },
  { label: "SLALOM", value: "SLALOM" },
  { label: "SOFTDENT", value: "SOFTDENT" },
  { label: "STHETOS", value: "STHETOS" },
  { label: "STREAM", value: "STREAM" },
  { label: "TURNBERRY SOLUTIONS", value: "TURNBERRY SOLUTIONS" },
  { label: "UBCARE", value: "UBCARE" },
  { label: "UDG HEALTHCARE", value: "UDG HEALTHCARE" },
  { label: "UNOS", value: "UNOS" },
  { label: "VA", value: "VA" },
  { label: "VALUCENTRIC", value: "VALUCENTRIC" },
  { label: "VINTURA", value: "VINTURA" },
  { label: "WEST CHESTER UNIVERSITY", value: "WEST CHESTER UNIVERSITY" },
  { label: "WILMINGTON HEALTHCARE", value: "WILMINGTON HEALTHCARE" },
  { label: "WPP", value: "WPP" },
  { label: "ZS ASSOCIATES", value: "ZS ASSOCIATES" },
  { label: "ZVA", value: "ZVA" },
  { label: "OTHER", value: "OTHER" },
];

export const update_frequency_list = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Annual", value: "annual" },
];

export const update_frequency_units = {
  monthly: "Months",
  quarterly: "Quarters",
  annual: "Years",
} as Record<string, string>;

export const currencies = [
  { label: "Euro", value: "EUR" },
  { label: "Dollar", value: "USD" },
];

export const volume_units = [
  { label: "Standard Units (SU)", value: "su" },
  { label: "Counting Units (CU)", value: "cu" },
  { label: "Days of Therapy (DoT)", value: "dot" },
  { label: "Packs", value: "pack" },
];

export const scopes_of_subscription = [{ label: "Full", value: "full" }];

export const formulation_class_types = [
  { label: "NFC", value: "nfc" },
  { label: "TLC", value: "tlc" },
];

export const therapy_class_types = [
  { label: "ATC EPHMRA", value: "ephrma_atc" },
  { label: "ATC WHO", value: "who_atc" },
  { label: "USC", value: "usc" },
];

export const price_levels = [
  { label: "Manufacturer Price", value: "manufacturer_price" },
  { label: "Ex-Manufacturer", value: "ex_manufacturer" },
  { label: "Wholesale Price", value: "whole_sale_price" },
  { label: "Retail Price", value: "retail_price" },
  { label: "List Price", value: "list_price" },
  { label: "Average Wholesale Price (AWP)", value: "awp" },
];

export const contact_owners = [{ label: "Martin Parr", value: "martin_parr" }];

export function getDataVendorLabel(data_vendor_value: string) {
  const vendors = data_vendor_list.filter(
    (data_vendor) => data_vendor.value === data_vendor_value,
  );
  if (vendors.length === 0) return "";

  return vendors[0].label;
}

export function getDataTypeLabel(data_type_value: string) {
  const data_types = data_type_list.filter(
    (data_type) => data_type.value === data_type_value,
  );
  if (data_types.length === 0) return "";

  return data_types[0].label;
}

export function getGranularityLabel(data_granularity_value: string) {
  const data_granularities = granularity_list.filter(
    (data_granularity) => data_granularity.value === data_granularity_value,
  );
  if (data_granularities.length === 0) return "";

  return data_granularities[0].label;
}

export function getUpdateFrequencyLabel(data_update_frequency_value: string) {
  const data_update_frequencies = update_frequency_list.filter(
    (data_frequency) => data_frequency.value === data_update_frequency_value,
  );
  if (data_update_frequencies.length === 0) return "";

  return data_update_frequencies[0].label;
}

export function getPanelGroupLabel(data_panel_group_value: string) {
  const panel_groups = panel_list.filter(
    (data_panel_group) => data_panel_group.value === data_panel_group_value,
  );
  if (panel_groups.length === 0) return "";

  return panel_groups[0].label;
}

export function getScopeOfSubscriptionLabel(
  data_scope_of_subscription_value: string,
) {
  const scopes = scopes_of_subscription.filter(
    (data_scope_of_subscription) =>
      data_scope_of_subscription.value === data_scope_of_subscription_value,
  );
  if (scopes.length === 0) return "";

  return scopes[0].label;
}

export function getChannelLabel(data_channel_value: string) {
  const channels = channel_list.filter(
    (data_channel) => data_channel.value === data_channel_value,
  );
  if (channels.length === 0) return "";

  return channels[0].label;
}

export function getCurrencyLabel(data_currency_value: string) {
  const currencies_list = currencies.filter(
    (data_currency) => data_currency.value === data_currency_value,
  );
  if (currencies_list.length === 0) return "";

  return currencies_list[0].label;
}

export function getFormulationClassTypeLabel(data_formulation_value: string) {
  const formulations = formulation_class_types.filter(
    (data_formulation) => data_formulation.value === data_formulation_value,
  );
  if (formulations.length === 0) return "";

  return formulations[0].label;
}

export function getTherapyClassTypeLabel(data_therapy_value: string) {
  const therapies = therapy_class_types.filter(
    (data_therapy_class) => data_therapy_class.value === data_therapy_value,
  );
  if (therapies.length === 0) return "";

  return therapies[0].label;
}

export function getVolumeUnitsLabel(data_volume_unit_value: string) {
  const units = volume_units.filter(
    (data_volume_unit) => data_volume_unit.value === data_volume_unit_value,
  );
  if (units.length === 0) return "";

  return units[0].label;
}

export function getVolumeUnitsListLabels(data_volume_unit_value: string) {
  const labels = data_volume_unit_value
    .split(",")
    .map((volume_unit) => getVolumeUnitsLabel(volume_unit));
  return labels;
}

export function getPriceLevelLabel(data_price_level_value: string) {
  const levels = price_levels.filter(
    (data_volume_unit) => data_volume_unit.value === data_price_level_value,
  );
  if (levels.length === 0) return "";

  return levels[0].label;
}

export function getCountries(countries_values: string[]) {
  return country_list.filter((country) =>
    countries_values.includes(country.value),
  );
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "paginateNotifications",
  pure: true,
})
export class PaginateNotificationsPipe implements PipeTransform {
  transform(
    values: any[],
    currentPageNumber: number,
    perPageNotifications: number,
  ): any[] {
    let start = (currentPageNumber - 1) * perPageNotifications;
    let end = start + perPageNotifications;
    return values.slice(start, end);
  }
}

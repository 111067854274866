import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { sourceCriteria } from "../sourceCriteria";

@Pipe({
  name: "sourcesDateOrderFilter",
})
export class SourcesDateOrderFilterPipe implements PipeTransform {
  transform(sources: any[], criteria: sourceCriteria): any[] {
    console.log("called");
    let selectedOrder = criteria.sorter;

    if (selectedOrder === "all") {
      return sources;
    }

    let order = (source1: any, source2: any) => 0;

    switch (selectedOrder) {
      case "last_created":
        order = (source1: any, source2: any) =>
          this.compareDates(source1.created_at, source2.created_at);
        break;
      case "last_modified":
        order = (source1: any, source2: any) =>
          this.compareDates(source1.modified_at, source2.modified_at);
        break;
      case "last_updated": //@TODO implement this when BE is corrected!
        order = (source1: any, source2: any) =>
          this.compareDates(source1.last_updated, source2.last_updated);
        break;
    }

    return sources.sort(order);
  }

  compareDates(date1: Date, date2: Date) {
    if (moment(date1).isSame(date2)) {
      return 0;
    }

    return moment(date1).isAfter(date2) ? -1 : 1;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { UndoQueue } from "../undoQueue";

@Pipe({
  name: "applyChangesToDataFilter",
  pure: true,
})
export class ApplyChangesFilter implements PipeTransform {
  transform(
    rule: any,
    changeLog: UndoQueue,
    primary_key: string[] | undefined,
    approveChangesIsEnabled: boolean,
  ): any {
    if (approveChangesIsEnabled) return rule;
    const data = ApplyChanges(rule, changeLog, primary_key);
    return data;
  }
}

export function ApplyChanges(
  rule: any,
  changeLog: UndoQueue,
  primary_key: string[] | undefined,
): any {
  if (rule["metadata"] && rule["metadata"]["inserted"])
    return ApplyChangesInserts(rule, changeLog);

  if (!primary_key || Object.keys(primary_key).length === 0) return rule;

  const primary_key_value = {} as Record<string, any>;
  primary_key.map(
    (key: string) =>
      (primary_key_value[key] = (rule as Record<string, any>)[key]),
  );

  const items = changeLog.getItems();
  const ruleChanges = items
    .filter((change: any) => change.contains(primary_key_value))
    .map((change: any) => (rule = change.apply(rule)));

  if (ruleChanges.length === 0) {
    const columns = Object.keys(rule);
    columns.map((column: string) => {
      if (column.startsWith("original_")) return;
      else rule[column] = rule["original_" + column];
    });
  }
  return rule;
}

export function ApplyChangesInserts(rule: any, changeLog: UndoQueue): any {
  const insertion_id = rule["metadata"]["insertion_id"];
  console.log("insertion _");

  const items = changeLog.getItems();
  const ruleChanges = items
    .filter((change: any) => change.insertion_id === insertion_id)
    .map((change: any) => (rule = change.apply(rule)));

  if (ruleChanges.length === 0) {
    const columns = Object.keys(rule);
    columns
      .filter((column: string) => column !== "metadata")
      .map((column: string) => {
        if (column.startsWith("original_") || column === "selection_id") return;
        else rule[column] = rule["original_" + column];
      });
  }
  console.log("insertion changes new value is ", rule);
  return rule;
}

import { Pipe, PipeTransform } from "@angular/core";
import { UndoQueue } from "../undoQueue";

@Pipe({
  name: "mergeInsertsFilter",
  pure: true,
})
export class MergeInsertsFilter implements PipeTransform {
  transform(rules: any[], inserts: any[], displayInserts: boolean): any {
    if (!displayInserts) return rules;
    return mergeInserts(rules, inserts);
  }
}

export function mergeInserts(rules: any[], inserts: any[]) {
  let newData = [...inserts, ...rules];
  newData = newData.map((rule: any, index: number) => {
    return { ...rule, selection_id: index };
  });

  console.log("new data is this ", newData);
  return newData;
}

function add_insert_metadata(rule: any) {
  return { ...rule, metadata: { inserted: true } };
}

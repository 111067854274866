<div>
    <p (click)="op.toggle($event)">All Updates Status</p>

    <p-overlayPanel #op>
        <div
            *ngFor="let item of items; let i = index"
            class="mine_dropdown_item"
            (mouseenter)="this.mouseEnteredChoice($event, i)"
            (mouseleave)="this.mouseLeftChoice($event, i)"
        >
            {{ item.label }}
            <p-checkbox />
            <div class="mine_dropdown_item_anchor" #choice></div>
        </div>
    </p-overlayPanel>
    <p-overlayPanel
        [appendTo]="this.firstChoice()"
        class="align_popup"
        #smallOverlay
    >
        I am here
    </p-overlayPanel>
</div>

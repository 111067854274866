import {
  Component,
  QueryList,
  ElementRef,
  ViewChildren,
  ViewChild,
  Renderer2,
} from "@angular/core";

@Component({
  selector: "app-mine-dropdown-with-overlay",
  templateUrl: "./mine-dropdown-with-overlay.component.html",
  styleUrls: ["./mine-dropdown-with-overlay.component.scss"],
})
export class MineDropdownWithOverlayComponent {
  items = [
    {
      label: "AAAAAAA",
    },
    {
      label: "BBBBBBBBBBB",
    },
    {
      label: "CCCCCC",
    },
  ];

  @ViewChildren("choice")
  children!: QueryList<ElementRef>;
  childrenAsArray!: any[];
  popupIsInited = false;
  @ViewChild("op")
  popup: any;
  @ViewChild("smallOverlay")
  smallOverlay: any;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.childrenAsArray = this.children.toArray();
    this.popupIsInited = true;
    //let childComponent = this.childrenComponents.find(childComponent => /* predicate on childComponent.model */);
    //let childComponentNativeElement = childComponent.elRef.nativeElement;
  }

  togglePopup(event: any) {}
  firstChoice() {
    if (this.childrenAsArray && this.currentIndex)
      return this.childrenAsArray[this.currentIndex];
    else "body";
  }

  currentIndex?: number;

  mouseEnteredChoice($event: any, index: number) {
    console.log("mouse entered");
    this.currentIndex = index;
    this.smallOverlay.show($event);
  }
  mouseLeftChoice($event: any, index: number) {
    console.log("mouse left");
    this.currentIndex == undefined;
    if (!this.currentIndex) this.smallOverlay.hide($event);
  }
}

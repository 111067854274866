<div
    id="upload_control"
    (drag)="fileDragged($event)"
    (drop)="fileDropped($event)"
    (dragover)="FileDragOver($event)"
    [ngClass]="{ upload_hidden: this.selectedFile || this.is_error }"
    [ngClass]="{ image_upload: this.mode === 'image' }"
>
    <app-upload-progress-indication
        *ngIf="this.upload_percentage !== 0"
        [completion_ratio]="this.upload_percentage"
        (uploadIsCancelled)="this.cancelUpload()"
    />
    <p-fileUpload
        #fileUpload
        (onSelect)="newFileSelection($event.files)"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [multiple]="false"
        [accept]="this.accept"
    >
        <ng-template pTemplate="toolbar" let-files>
            <div
                id="container"
                *ngIf="this.selectedFile === null && !this.is_error"
            >
                <img src="assets/icons/upload.svg" />
                <p
                    style="margin-top: 17px; color: black"
                    *ngIf="!this.showProgression"
                >
                    <a (click)="displayUploadDialog()">{{
                        this.getUploadLabel()
                    }}</a>
                    or drag and drop
                </p>
                <p style="margin-top: 6px; color: #8e9397">
                    {{ this.getSizeNotice() }}
                </p>
            </div>
        </ng-template>
        <ng-template pTemplate="file" let-file> </ng-template>
        <ng-template pTemplate="content" let-files> </ng-template>
    </p-fileUpload>
    <div id="file_information" *ngIf="this.selectedFile !== null">
        <img id="file_icon" src="assets/icons/file.svg" />
        <p>{{ selectedFile.name }}</p>
        <img
            id="delete_file_icon"
            (click)="deleteFile()"
            src="assets/icons/file_delete.svg"
        />
    </div>
</div>

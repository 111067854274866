<div id="brm_table_paginator">
    <img
        (click)="this.goToFirst()"
        src="assets/icons/paginator/previous.png"
        class="clickable"
    />
    <p (click)="this.previousPage()" class="clickable">Previous</p>
    <div id="brm_table_paginator_numbers">
        <ng-container *ngFor="let page of this.pages()">
            <p
                (click)="this.navigateToPage(page.number)"
                class="page_number clickable"
                [ngClass]="{ selected: page.number === this.current_page }"
                *ngIf="page.visible"
            >
                {{ page.caption }}
            </p>
        </ng-container>
    </div>
    <p (click)="this.nextPage()" class="clickable">Next</p>
    <img
        (click)="this.goToLast()"
        class="clickable"
        src="assets/icons/paginator/next.png"
    />
</div>

import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as _ from "lodash";

export const MINE_DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MineDropdownComponent),
  multi: true,
};

export interface MineDropdownValue {
  is_other: boolean;
  value: string;
}

@Component({
  selector: "app-mine-dropdown",
  templateUrl: "./mine-dropdown.component.html",
  styleUrls: ["./mine-dropdown.component.scss"],
  providers: [MINE_DROPDOWN_VALUE_ACCESSOR],
})
export class MineDropdownComponent implements ControlValueAccessor, OnInit {
  showOtherInput(): any {
    console.log("selected_value ", this.selected_value);
    return this.selected_value.includes("other");
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  @Input() options: any[] = [
    {
      label: "Spain",
      value: "spain",
    },
    {
      label: "France",
      value: "france",
    },
  ];

  @Input() enbale_other_value = false;
  @Input() enable_other = true;
  @Input() placeholder = "";
  @Input() other_placeholder = "";
  @Input() other_label = "";
  @Input() other_list_label = "Other";

  internal_value: any;
  onChange: any;
  onTouched: any;
  isDisabled: any;
  other_value = "";

  selected_value = [] as string[];

  ngOnInit(): void {
    if (this.enable_other)
      this.options = [
        ...this.options,
        { label: this.other_list_label, value: "other" },
      ];
  }

  selected() {
    if (this.selected_value.flat(1).includes("other")) {
      console.log("here man!", this.options);
      return this.other_list_label;
    } else return this.selected_value[0];
  }

  get value() {
    return this.internal_value;
  }

  set value(val) {
    this.internal_value = val;
  }

  writeValue(obj: any): void {
    if (typeof obj === "string" || obj instanceof String) {
      let options_values = this.options.map((option: any) => option.value);

      console.log("it's a string");
      if (options_values.includes(obj)) obj = { is_other: false, value: obj };
      else obj = { is_other: true, value: obj };
    }
    console.log("writing value");

    this.internal_value = obj;
    if (obj.is_other) {
      this.selected_value = ["other"];
      this.other_value = obj["value"];
    } else {
      this.selected_value = [obj["value"]];
    }
  }

  registerOnChange(fn: any): void {
    console.log("on change: registered ", fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  last_selected_list_item: string | undefined;

  inputChanged(event: any) {
    if (event.value.length === 0 && this.last_selected_list_item) {
      console.log("selected_value is ", this.selected_value);
      this.selected_value = [this.last_selected_list_item];
      console.log("input changed");
      return;
    }

    this.last_selected_list_item = _.last(event.value) as string;
    const newValue = _.last(event.value) as string;
    console.log("new value", newValue);
    this.selected_value = [newValue];
    console.log("selected_value", this.selected_value);
    this.onChange({ is_other: false, value: this.selected_value });
    console.log("on change emitted");
  }

  otherChanged(event: any) {
    this.onChange({ is_other: true, value: event.target.value });
    console.log("on change emitted ", event.target.value);
  }

  blur() {
    this.onTouched();
  }
}

import { Component, Input } from "@angular/core";
import { setDelaysChartStepSize } from "../core/models/helpers";
import { computeChartSettings } from "./bars_chart_utils";

@Component({
  selector: "app-bars-chart",
  templateUrl: "./bars-chart.component.html",
  styleUrls: ["./bars-chart.component.scss"],
})
export class BarsChartComponent {
  @Input() delays_data: any;

  get_chart_options() {
    if (!this.delays_data) return {};
    if (!this.delays_data.datasets) return {};
    if (this.delays_data.datasets.length === 0) return {};
    console.log("bars data is ", this.delays_data.datasets[0].data);
    let new_data_set = this.delays_data;
    let step_size = 1;
    const numbersOnly = new_data_set.datasets[0].data.filter(
      (item: any) => typeof item === "number",
    );
    const configuration = computeChartSettings(numbersOnly);

    return setDelaysChartStepSize(configuration.step_size, configuration.max);
  }
}

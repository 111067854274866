export function buildChartData(data: any[], color = "#367CF6") {
  if (data.length === 1) {
    data = ["", ...data, ""];
  }
  return {
    data: [...data],
    barPercentage: 0.4,
    backgroundColor: color,
  };
}

export function buildMultipleChartData(multiData: any[][]) {
  const colors = ["#367CF6", "#F59C4A"];
  return multiData.map((data: any[], index: number) =>
    buildChartData(data, colors[index % 2]),
  );
}

export function computeChartSettings(data: number[]): {
  step_size: number;
  max: number;
} {
  if (data.length === 0) {
    return {
      step_size: 1,
      max: 1,
    };
  }

  if (data.every((num: number) => num < 50)) {
    console.log("values too low!");
    return {
      step_size: 1,
      max: 5,
    };
  }

  let max = Math.max(...data);
  let new_max = roundToNearestMultipleBasedOnValue(Math.max(...data));
  let step_size = new_max / 5;
  if (max < new_max / 2) {
    new_max = new_max / 2;
    step_size = step_size / 2;
  }

  if (data.every((point: number) => point < new_max / 2)) {
    new_max = new_max / 2;
  }

  return {
    step_size: step_size,
    max: new_max,
  };
}

export function smallestMultipleOverM(N: number, M: number) {
  return (Math.floor(M / N) + 1) * N;
}

export function roundToNearestMultipleBasedOnValue(number: number) {
  const baseTen = range(1, 50).map((n: number) => Math.pow(10, n));
  let firstThatsBigger = baseTen.find((n: number) => number < n);
  if (firstThatsBigger) return firstThatsBigger;
  return 10;
}

function range(start: number, end: number) {
  const numbers = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }
  return numbers;
}

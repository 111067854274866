import { Pipe, PipeTransform } from "@angular/core";
import { __ } from "lodash";

@Pipe({
  name: "changesFilter",
  pure: true,
})
export class ChangesFilterPipe implements PipeTransform {
  transform(
    rows: any[],
    showOnlyChanged: boolean,
    pendingRequests: any[],
  ): any[] {
    if (showOnlyChanged) {
      console.log("changes to display ", changedRules(pendingRequests, rows));
      const new_rows = pendingRequests
        .filter((request: any) => request["type"] === "insert")
        .map((insertion: any) => JSON.parse(insertion.insertion))
        .map((insertion: any) => ({
          ...insertion,
          metadata: { inserted: true },
        }));
      const changed = changedRules(pendingRequests, rows);
      let finals = [...changed, ...new_rows];
      finals = finals.map((value: any, index: number) => ({
        ...value,
        selection_id: index,
      }));
      return finals;
    } else return rows;
  }
}

export function changedRules(pendingRequests: any[], rows: any[]) {
  const changed_rows_keys = pendingRequests
    .filter((request: any) => request["type"] === "edit")
    .map((request: any) => request.change_key);

  const final_rows_keys = [] as any[];

  changed_rows_keys.map((key: any) => {
    const sameKey = final_rows_keys.find((keyToFind: any) =>
      sameObject(key, keyToFind),
    );
    if (!sameKey) final_rows_keys.push(key);
  });

  let res = rows.filter((row: any) => {
    return hasKey(row, final_rows_keys);
  });

  return res;
}

export function sameObject(objA: any, objB: any) {
  return Object.keys(objA)
    .map((key: string) => objA[key] === objB[key])
    .every((state: boolean) => state);
}

function hasKey(row: any, keys: any[]) {
  const bools = keys.map((key: any) => {
    for (const [current_key, value] of Object.entries(key)) {
      if (row[current_key] !== value) {
        return false;
      }
    }
    return true;
  });
  return bools.some((val: boolean) => val);
}

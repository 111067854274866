<div id="container">
    <div id="charts_container">
        <div>
            <h3>Current Records</h3>
            <p-chart
                type="line"
                [data]="this._current_records_data()"
                [options]="this.current_records_chart_options()"
                height="350px"
            ></p-chart>
        </div>
        <div>
            <h3>Unique Values</h3>
            <p-chart
                type="line"
                [data]="this._unique_values_data()"
                [options]="this.unique_values_chart_options()"
                height="350px"
            ></p-chart>
        </div>
        <div>
            <h3>Undefined Values</h3>
            <p-chart
                type="line"
                [data]="this._percent_blanks_data()"
                [options]="this.undefined_values_chart_options()"
                height="350px"
            ></p-chart>
        </div>
    </div>
    <div id="legend">
        <img src="./assets/img/attribute_chart.svg" />
        <p>{{ this.field_name }}</p>
    </div>
</div>

<div>
    <div>
        <div id="horizontal_menu">
            <p-toast [style]="{ zindex: 1000 }">
                <ng-template let-message pTemplate="message">
                    <p innerHtml="{{ message.detail }}"></p>
                </ng-template>
            </p-toast>
            <div class="sign">
                <img src="assets/icons/menu/sign.svg" alt="Sign" />
            </div>
            <div id="right_menu">
                <div
                    class="chevron_container"
                    (click)="this.navigateToDirection('backwards')"
                >
                    <img
                        [src]="this.navigationButtonIcon('left')"
                        alt="Sign"
                        class="chevron"
                    />
                </div>
                <div
                    style="margin-right: 16px"
                    class="chevron_container"
                    (click)="this.navigateToDirection('forward')"
                >
                    <img
                        [src]="this.navigationButtonIcon('right')"
                        alt="Sign"
                        class="chevron"
                    />
                </div>
                <div id="logos">
                    <img
                        src="./assets/img/mine_mangabey_blue_green.svg"
                        alt=""
                    />
                    <svg
                        width="1"
                        height="28"
                        viewBox="0 0 1 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1="0.5" x2="0.5" y2="28" stroke="#EBECED" />
                    </svg>

                    <img
                        src="./assets/img/Johnson_and_Johnson_Logo.svg"
                        alt=""
                    />
                </div>
            </div>

            <div id="right_corner">
                <div id="button_panel">
                    <button
                        id="second_button"
                        class="menu_button"
                        (click)="openNewSourceModal()"
                    >
                        Add New Data Source
                    </button>
                </div>
                <div
                    id="profile_image"
                    (click)="this.showProfileMenu()"
                    style="width: 70px; margin-left: 20px"
                >
                    <img
                        id="profile_image"
                        class="profile_image"
                        [src]="this.accountService.profile_picture_link"
                        (click)="this.showProfileMenu()"
                        #profile_picture
                    />
                    <div
                        style="position: absolute; z-index: 1000; right: 10px"
                        *ngIf="this.profileMenuIsShown"
                    >
                        <p-menu #op [model]="this.items" [popup]="false">
                            <ng-template pTemplate="start">
                                <div class="user_info">
                                    <h1>
                                        {{ this.getCurrentUserRole() }}
                                    </h1>
                                    <h2>
                                        {{
                                            this.currentUser["account"][
                                                "full_name"
                                            ]
                                        }}
                                    </h2>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="item" let-item>
                                <div
                                    class="profile_menu_item clickable"
                                    (click)="this.menuChosen(item.value)"
                                >
                                    <img src="assets/icons/{{ item.icon }}" />
                                    <p>
                                        {{ item.label }}
                                    </p>
                                </div>
                            </ng-template>
                        </p-menu>
                    </div>
                </div>
            </div>
        </div>

        <div id="content_wrapper">
            <div id="menu" #menu>
                <menu-item [icon]="'shopping_bag'"></menu-item>
                <menu-item [icon]="'data_usage'"></menu-item>
                <div #intelligence_center_menu>
                    <menu-item
                        [icon]="'file_lock'"
                        [isActive]="fileLockMenuIsActive"
                        (click)="showSubMenu()"
                    ></menu-item>
                </div>
                <menu-item [icon]="'team'"></menu-item>
            </div>
            <new-data-source-dialog></new-data-source-dialog>
            <div id="global_container">
                <div id="sub_menu" [style.display]="sub_menu_display" #sub_menu>
                    <p-card [style.font-family]="'satoshi'">
                        <ng-template pTemplate="header">
                            <img
                                src="assets/icons/exit.svg"
                                class="exit_button"
                                (click)="closeButtonClicked()"
                            />
                            <p class="menu_title" style="margin-bottom: 20px">
                                Intelligence Center
                            </p>
                        </ng-template>

                        <div class="menu_group">
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName === 'home',
                                }"
                            >
                                <a routerLink="/home">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName === 'home'
                                                ? 'hover/'
                                                : ''
                                        }}home_page.svg"
                                        alt=""
                                    />
                                    <p>Home Page</p>
                                </a>
                            </div>
                        </div>
                        <div class="menu_group">
                            <h2>Data Library</h2>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'all_sources',
                                }"
                            >
                                <a [routerLink]="['/']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'all_sources'
                                                ? 'hover/'
                                                : ''
                                        }}all_data_sources.svg"
                                        alt=""
                                    />
                                    <p>All Data Sources</p>
                                </a>
                            </div>
                        </div>
                        <div class="menu_group">
                            <h2>Data Manager</h2>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'business_data',
                                }"
                            >
                                <a [routerLink]="['/business_data']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'business_data'
                                                ? 'hover/'
                                                : ''
                                        }}business_data.svg"
                                        alt=""
                                    />
                                    <p>Business Data</p>
                                </a>
                            </div>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'business_rules',
                                }"
                            >
                                <a [routerLink]="['business_rules']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'business_rules'
                                                ? 'hover/'
                                                : ''
                                        }}business_rules.svg"
                                        alt=""
                                    />
                                    <p>Business Rules</p>
                                </a>
                            </div>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'system_data',
                                }"
                            >
                                <a [routerLink]="['system_data']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'system_data'
                                                ? 'hover/'
                                                : ''
                                        }}system_data.svg"
                                        alt=""
                                    />
                                    <p>System Data</p>
                                </a>
                            </div>
                        </div>
                        <div class="menu_group">
                            <h2>Update Center</h2>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'all_updates',
                                }"
                            >
                                <a [routerLink]="['/updates/all']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'all_updates'
                                                ? 'hover/'
                                                : ''
                                        }}all_updates.svg"
                                        alt=""
                                    />
                                    <p>All Updates</p>
                                </a>
                            </div>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'pending_updates',
                                }"
                            >
                                <a [routerLink]="['updates/pending']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'pending_updates'
                                                ? 'hover/'
                                                : ''
                                        }}pending_updates.svg"
                                        alt=""
                                    />
                                    <p>Pending</p>
                                </a>
                            </div>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'in_progress_updates',
                                }"
                            >
                                <a [routerLink]="['updates/in_progress']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'in_progress_updates'
                                                ? 'hover/'
                                                : ''
                                        }}in_progress_updates.svg"
                                        alt=""
                                    />
                                    <p>In Progress</p>
                                </a>
                            </div>
                            <div
                                class="route"
                                [ngClass]="{
                                    selected_route:
                                        this.currentSubRouteName ===
                                        'completed_updates',
                                }"
                            >
                                <a [routerLink]="['updates/completed']">
                                    <img
                                        src="assets/icons/menu/{{
                                            this.currentSubRouteName ===
                                            'completed_updates'
                                                ? 'hover/'
                                                : ''
                                        }}completed_updates.svg"
                                        alt=""
                                    />
                                    <p>Completed</p>
                                </a>
                            </div>
                        </div>
                        <div class="menu_group">
                            <h2>Quality Center</h2>
                            <div class="route">
                                <a [routerLink]="['quality_center_dashboard']">
                                    <img
                                        src="assets/icons/menu/dashboard.svg"
                                        alt=""
                                    />
                                    <p>Dashboard</p>
                                </a>
                            </div>
                        </div>
                    </p-card>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<div>
    <p-table
        *ngIf="!this.isEmpty()"
        [value]="this.getArrayData()"
        styleClass="p-datatable-gridlines"
        [ngClass]="{ 'min-size': !this.isEmpty() }"
        scrollHeight="{{ this.isEmpty() ? undefined : '300px' }}"
        [scrollable]="true"
    >
        <ng-template pTemplate="header">
            <ng-container *ngIf="this.isKPITable">
                <tr class="very_upper_header">
                    <th rowspan="2" style="color: black">Field</th>
                    <th colspan="2">Records</th>
                    <th colspan="2">Unique Values</th>
                    <th colspan="2">Undefined Values</th>
                </tr>
                <tr>
                    <ng-container *ngFor="let i of [1, 2, 3]">
                        <th
                            style="text-align: center !important"
                            class="no_upper_border"
                        >
                            Current
                        </th>
                        <th class="no_upper_border">New</th>
                    </ng-container>
                </tr>
            </ng-container>
            <tr *ngIf="!this.isKPITable">
                <th *ngFor="let header of this.getHeaders()">
                    {{ header.name }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr
                (click)="internalRowClicked(item)"
                (mouseenter)="internalRowMouseEnter(item)"
                (mouseleave)="internalRowMouseLeave()"
            >
                <td *ngFor="let header of this.headers">
                    {{ this.formatIfNumber(item[header.key]) }}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-treeTable
        *ngIf="this.treeData"
        [value]="this.treeData"
        [columns]="this.getColumns()"
        [scrollable]="true"
        styleClass="p-datatable-gridlines"
        [ngClass]="{ 'min-size': !this.isEmpty() }"
        scrollHeight="{{ this.isEmpty() ? undefined : '300px' }}"
        class="mine_tree_table"
    >
        <ng-template pTemplate="header" let-columns>
            <ng-container *ngIf="this.isKPITable">
                <tr>
                    <th rowspan="2" class="field_header">Field</th>
                    <th colspan="2">Records</th>
                    <th colspan="2">Unique Values</th>
                    <th colspan="2">Undefined Values</th>
                </tr>
                <tr>
                    <ng-container *ngFor="let i of [1, 2, 3]">
                        <th
                            class="no_upper_border"
                            style="
                                text-align: center !important;
                                border-left-style: none;
                            "
                        >
                            Current
                        </th>
                        <th class="no_upper_border">New</th>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-rowNode
            let-rowData="rowData"
            let-columns="columns"
        >
            <tr
                [ttRow]="rowNode"
                (click)="internalRowClicked(rowData)"
                [ngClass]="{
                    selected_row:
                        this.selectionPredicate &&
                        this.selectionPredicate(rowData),
                }"
            >
                <td
                    *ngFor="let col of columns; let i = index"
                    [ngClass]="{
                        group_label:
                            rowData['id'] === undefined && this.useIdForGroup,
                    }"
                    [ngStyle]="{
                        'text-wrap':
                            i === 0 && !rowData['id'] ? 'nowrap' : 'initial',
                        width: i === 0 && !rowData['id'] ? '220px' : 'auto',
                    }"
                >
                    <p-treeTableToggler
                        [rowNode]="rowNode"
                        *ngIf="i === 0 && !rowData['id']"
                    ></p-treeTableToggler>
                    <ng-container *ngIf="i === 0">
                        <p
                            [innerHTML]="rowData['label']"
                            style="display: inline !important"
                        ></p>
                    </ng-container>
                    <ng-container
                        *ngIf="rowData['sum'] && col.field !== 'field_name'"
                    >
                        {{ this.formatIfNumber(rowData["sum"][col.field]) }}
                    </ng-container>
                    <ng-container
                        *ngIf="rowData['tooltip'] && col.field === 'field_name'"
                    >
                        <img
                            src="assets/icons/tooltip_icon.svg"
                            style="padding-left: 6px"
                            [pTooltip]="rowData['tooltip']"
                        />
                    </ng-container>
                    {{ this.formatIfNumber(rowData[col.field]) }}
                </td>
            </tr>
        </ng-template>
    </p-treeTable>

    <div class="no_data_message" *ngIf="this.isArrayData() && this.isEmpty()">
        <p>No Data Available.</p>
    </div>
</div>

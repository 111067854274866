<p-confirmDialog key="refresh_dialog" class="refresh_dialog">
    <ng-template pTemplate="header">
        <div>
            <h2>Refresh Data Source</h2>
            <h3>Upload your data sources for further work</h3>
        </div>
        <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
        <div id="container" style="margin: 24px">
            <supported-formats></supported-formats>
        </div>
        <div id="end_period" style="margin: 24px">
            <label for="">End Period</label>
            <p-calendar
                [(ngModel)]="this.endPeriod"
                view="month"
                dateFormat="mm.yy"
            ></p-calendar>
        </div>
        <div style="margin: 24px; margin-bottom: 0px">
            <upload-control
                (filesChanged)="updateSelectedFile($event)"
                [fileName]="this.uploadFileName()"
            ></upload-control>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button class="white_button" (onClick)="closeDialog()"
            >Cancel</p-button
        >
        <p-button id="confirm_button" (onClick)="submitDialog()">Save</p-button>
    </ng-template>
</p-confirmDialog>

<div id="wrapper">
    <h1>Settings</h1>
    <app-confirm-change-password></app-confirm-change-password>
    <p id="description">
        Update your preferences to personalize your settings and improve your
        experience.
    </p>
    <div id="settings_menu">
        <div
            *ngFor="let sub_menu of this.sub_menus"
            [ngClass]="{ selected: sub_menu.id === this.selected_sub_menu }"
        >
            <div
                class="settings_submenu"
                (click)="this.changeSelectedSubMenu(sub_menu.id)"
            >
                <img src="./assets/icons/settings_icons/{{ sub_menu.icon }}" />
                <div>
                    <h2>{{ sub_menu.title }}</h2>
                    <p>{{ sub_menu.description }}</p>
                </div>
            </div>
        </div>
    </div>

    <div
        class="settings_editor"
        class="editor"
        *ngIf="this.selected_sub_menu === 'profile'"
    >
        <div class="toolbar_header" style="width: 100%">
            <h3 *ngIf="this.selected_sub_menu === 'profile'">User</h3>
        </div>
        <div
            class="toolbar_header"
            style="display: flex; justify-content: end"
            [ngStyle]="{
                'justify-content':
                    this.selected_sub_menu === 'profile' ? 'end' : 'start',
            }"
        >
            <div id="buttons_container">
                <div
                    class="brm_button clickable"
                    *ngIf="
                        this.selected_sub_menu === 'profile' &&
                        this.is_edit_mode
                    "
                    (click)="this.cancelChanges()"
                >
                    <p>Cancel</p>
                </div>
                <div
                    class="brm_button clickable commit_changes"
                    *ngIf="
                        this.selected_sub_menu === 'profile' &&
                        this.is_edit_mode
                    "
                >
                    <p (click)="this.save()">Save</p>
                </div>
                <div
                    class="brm_button clickable"
                    *ngIf="
                        this.selected_sub_menu === 'profile' &&
                        !this.is_edit_mode
                    "
                    (click)="this.enableEditMode()"
                >
                    <img src="assets/icons/edit_mode_brm.svg" />
                    <p>Edit</p>
                </div>
            </div>
        </div>
        <div>
            <h4>Profile picture</h4>
        </div>
        <div>
            <img
                *ngIf="!this.is_edit_mode"
                [src]="this.profile_picture"
                class="profile_picture"
                style="border-radius: 50px"
            />
            <div style="width: 300px">
                <upload-control
                    [mode]="'image'"
                    *ngIf="this.is_edit_mode"
                    (filesChanged)="newProfilePictureUpload($event)"
                ></upload-control>
            </div>
        </div>
        <div>
            <h4>Email</h4>
        </div>
        <div>
            <p>{{ this.currentUser.user.email }}</p>
        </div>
        <div>
            <h4>Name</h4>
        </div>
        <div>
            <p>{{ this.currentUser.account.full_name }}</p>
        </div>
        <div>
            <h4>Password</h4>
        </div>
        <div>
            <svg
                *ngIf="!this.is_edit_mode"
                style="height: 20px"
                viewBox="0 0 500 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    [attr.cx]="20 + i * 60"
                    cy="50"
                    r="20"
                    *ngFor="let point of this.passwordPoints(); index as i"
                />
            </svg>
            <div
                class="brm_button clickable"
                *ngIf="this.is_edit_mode"
                style="width: fit-content"
                (click)="this.openPasswordChangeConfirmation()"
            >
                <p>Change password...</p>
            </div>
        </div>

        <div>
            <h4>Role</h4>
        </div>
        <div>
            <p>{{ this.formatRole() }}</p>
        </div>
    </div>
    <div
        id="notifications_edit"
        class="editor notifications_split"
        *ngIf="this.selected_sub_menu === 'notifications'"
    >
        <div class="toolbar_header" style="width: 100%">
            <h3 *ngIf="this.selected_sub_menu === 'notifications'">General</h3>
        </div>
        <div class="toolbar_header" style="width: 100%">
            <h3 *ngIf="this.selected_sub_menu === 'notifications'">Email</h3>
        </div>

        <ng-container *ngFor="let setting of this.notifications_settings">
            <div>
                <h4>{{ setting.label }}</h4>
                <p>{{ setting.description }}</p>
            </div>
            <div>
                <p-checkbox></p-checkbox>
            </div>
        </ng-container>
    </div>
</div>

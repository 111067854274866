import { Component, Input } from "@angular/core";
import { activity } from "../brm-activity-log/brm-activity-log.component";
import { BRMServiceService } from "../core/services/brmservice.service";
import { capitalizeFirstLetter } from "../core/models/helpers";

@Component({
  selector: "app-brm-activity-history",
  templateUrl: "./brm-activity-history.component.html",
  styleUrls: ["./brm-activity-history.component.scss"],
})
export class BrmActivityHistoryComponent {
  constructor(private brmService: BRMServiceService) {}
  @Input() selected_cell_key: object | undefined;
  @Input() displayApproveRejection: boolean = true;

  @Input() height: number | undefined;
  @Input() activities: activity[] = [];
  @Input() activity_display_limit: number | undefined;
  @Input() userAtLeastManager: boolean = false;

  get_activities() {
    console.log("activities: ", this.activities);
    if (this.activity_display_limit)
      return this.activities.slice(0, this.activity_display_limit);
    else return this.activities;
  }
  approveChanges(change_id: number) {
    this.brmService.approveChanges(change_id).subscribe(() => {
      alert("approved");
    });
  }
  jsonify(obj: any) {
    return JSON.stringify(obj);
  }
  rejectChanges(change_id: number) {
    this.brmService.rejectChanges(change_id).subscribe(() => {
      alert("rejected");
    });
  }
  getProfilePic(activity: any) {
    if (activity.updated_by === "Perla Saker") return "ps.png";
    else return "kb.png";
  }

  getMessageEdit(activity: any) {
    if (activity.status === "pending")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Pending approval</span>`;
    if (activity.status === "approved")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Approved</span> by <span class='user'>${activity.approved_by_full_name}</span> . ${activity.approved_at}`;
    if (activity.status === "rejected")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Rejected</span> by <span class='user'>${activity.rejected_by_full_name}</span> . ${activity.rejected_at}`;
    else return "";
  }

  getFirstMessageEdit(activity: any) {
    return `
        <span> ${activity.column_name}</span> updated from
        <span>${activity.old_value}</span>
        to
        <span>${activity.new_value}</span>`;
  }

  getFirstMessageInsertion(activity: any) {
    return `${this.formatTableName(activity.table_name)} has a new insertion`;
  }

  formatTableName(table_name: string) {
    //TODO fix this to use the business name of table and not just format the technical name
    return capitalizeFirstLetter(table_name.split("_").join(" "));
  }

  //TODO this function is a duplicate of the other function for edits
  getMessageInsertion(activity: any) {
    if (activity.status === "pending")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Pending approval</span>`;
    if (activity.status === "approved")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Approved</span> by <span class='user'>${activity.approved_by_full_name}</span> . ${activity.approved_at}`;
    if (activity.status === "rejected")
      return `Updated by <span class='user'>${activity.created_by_full_name}</span> . ${activity.created_at} | <span class='status'>Rejected</span> by <span class='user'>${activity.rejected_by_full_name}</span> . ${activity.rejected_at}`;
    else return "";
  }

  getBigMessage(activity: any) {
    console.log(activity.created_by);
    console.log(activity.status);
    if (activity["type"] === "edit") return this.getFirstMessageEdit(activity);
    else return this.getFirstMessageInsertion(activity);
  }

  getMessage(activity: any) {
    console.log(activity.created_by);
    console.log(activity.status);
    if (activity["type"] === "edit") return this.getMessageEdit(activity);
    else return this.getMessageInsertion(activity);
  }
}

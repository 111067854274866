import { Component, Input } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { getCountryName } from "src/app/core/models/country_codes";
import { Source } from "src/app/core/models/source";
import { SourceService } from "src/app/core/services/source.service";
import { getPanelGroupLabel } from "src/app/landing/my/filters-data";

@Component({
  selector: "refresh-data-source",
  templateUrl: "./refresh-data-source.component.html",
  styleUrls: ["./refresh-data-source.component.scss"],
})
export class RefreshDataSourceComponent {
  end_period_invalid = false;
  constructor(
    private confirmationService: ConfirmationService,
    private sourceService: SourceService,
  ) {}

  @Input() currentSource: Source | null = null;
  selectedFile: File | null = null;
  endPeriod = new Date();

  updateSelectedFile(file: File | null) {
    this.selectedFile = file;
  }

  convertToDjangoFormat(date: Date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00`;
  }

  formatEndPeriod(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Pad month with leading zero if needed
    return `${year}${month}`;
  }
  uploadFileName() {
    if (
      this.currentSource &&
      this.currentSource.country &&
      this.currentSource.panel_group
    ) {
      console.log("current source is ", this.currentSource);
      const countryLabel = getCountryName(this.currentSource.country);
      const panelLabel = getPanelGroupLabel(this.currentSource.panel_group);

      return `MINE-${countryLabel}-${panelLabel}-${this.formatEndPeriod(this.endPeriod)}-RAW.csv`;
    }
    return "";
  }

  submitDialog() {
    const newSource = {} as Record<string, any>;

    if (this.currentSource !== null) {
      newSource["end_period"] =
        `${this.endPeriod.getFullYear()}-${this.endPeriod.getMonth() + 1}-${this.endPeriod.getDate()}T00:00`;
      newSource["data_upload"] = this.selectedFile ?? undefined;
      if (this.currentSource !== null && this.currentSource.id)
        this.sourceService
          .refreshSource(newSource, this.currentSource.id)
          .subscribe(() => this.closeDialog());
    }
  }

  closeDialog() {
    this.confirmationService.close();
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { UndoQueue } from "../undoQueue";

@Pipe({
  name: "applyChangesBulkFilter",
  pure: false,
})
export class ApplyChangesToDataFilter implements PipeTransform {
  transform(
    rules: any[],
    changeLog: UndoQueue,
    primary_key: string[] | undefined,
    approveChangesIsEnabled: boolean,
  ): any {
    return rules;
  }
}

export function ApplyChanges(
  rules: any[],
  changeLog: UndoQueue,
  primary_key: string[] | undefined,
): any {
  console.log("number:", changeLog.getItems().length);

  //TODO don't map on all rules just filter the changed ones and apply changes
  return rules.map((rule: any) => {
    console.log("apply changes: here");
    if (rule["metadata"] && rule["metadata"]["inserted"])
      return ApplyChangesInserts(rule, changeLog);

    if (!primary_key || Object.keys(primary_key).length === 0) return rule;

    const primary_key_value = {} as Record<string, any>;
    primary_key.map(
      (key: string) =>
        (primary_key_value[key] = (rule as Record<string, any>)[key]),
    );

    const items = changeLog.getItems();
    console.log("rules with changes shere", items);
    const ruleChanges = items
      .filter((change: any) => change.contains(primary_key_value))
      .map((change: any) => (rule = change.apply(rule)));

    if (ruleChanges.length === 0) {
      console.log("no changes");
      const columns = Object.keys(rule);
      columns.map((column: string) => {
        if (column.startsWith("original_")) return;
        else rule[column] = rule["original_" + column];
      });
    } else {
      console.log("got one that changed here ", ruleChanges);
    }
    if (rule["category_sortkey"] === 1003) {
      console.log("correct rule", rule);
    }
    return { ...rule };
  });
}

export function ApplyChangesInserts(rule: any, changeLog: UndoQueue): any {
  const insertion_id = rule["metadata"]["insertion_id"];
  console.log("insertion _");

  const items = changeLog.getItems();
  const ruleChanges = items
    .filter((change: any) => change.insertion_id === insertion_id)
    .map((change: any) => (rule = change.apply(rule)));

  if (ruleChanges.length === 0) {
    const columns = Object.keys(rule);
    columns
      .filter((column: string) => column !== "metadata")
      .map((column: string) => {
        if (column.startsWith("original_") || column === "selection_id") return;
        else rule[column] = rule["original_" + column];
      });
  }
  console.log("insertion changes new value is ", rule);
  return rule;
}

import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { BRMServiceService } from "../core/services/brmservice.service";

export interface activity {
  type: string;
  column_name: string;
  new_value: string;
  old_value: string;
  updated_by: string | null;
  approved_by: string | null;
  rejected_by: string | null;

  update_time: string | null;
  approve_time: string | null;
  rejection_time: string | null;
  created_by_profile_picture: string | null;
}

@Component({
  selector: "app-brm-activity-log",
  templateUrl: "./brm-activity-log.component.html",
  styleUrls: ["./brm-activity-log.component.scss"],
})
export class BrmActivityLogComponent implements OnInit {
  constructor(private brmService: BRMServiceService) {}

  activityLogClosed() {
    this.logClosed.emit();
  }
  @Output() logClosed = new EventEmitter();
  @Output() userAskedToGoToRow = new EventEmitter<number>();

  row_number = 4;
  @Input() selected_cell_key: object | undefined;

  @Input() activities: activity[] = [];
  @Input()
  userAtLeastManager: boolean = false;

  ngOnInit() {
    console.log("activities from the other side ", this.activities);
  }

  getMessage(activity: any) {
    if (activity.status === "pending")
      return `Updated by <span class='user'>${activity.created_by}</span> . ${activity.created_at} | <span class='status'>Pending approval</span>`;
    if (activity.status === "approved")
      return `Updated by <span class='user'>${activity.created_by}</span> . ${activity.created_at} | <span class='status'>Approved</span> by <span class='user'>${activity.approved_by}</span> . ${activity.approved_at}`;
    if (activity.status === "rejected")
      return `Updated by <span class='user'>${activity.created_by}</span> . ${activity.created_at} | <span class='status'>Rejected</span> by <span class='user'>${activity.rejected_by}</span> . ${activity.rejected_at}`;
    else return "";
  }
}

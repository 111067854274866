import * as _ from "lodash";
import { activity } from "../brm-activity-log/brm-activity-log.component";
import { AccountService } from "../core/services/account.service";
import { inject } from "@angular/core";
import { AuthService } from "../core/services/auth.service";

export interface baseEdit {
  getIDs(): object;
  contains(id: object): boolean;
  apply(rule: any): any;
}

export interface singleEdit extends baseEdit {
  id: object;
  field: string;
  newValue: any;
  oldValue: any;

  getIDs(): object[];
  apply(rule: any): any;
}

export interface batchEdit extends baseEdit {
  id: object[];
  field: string;
  newValue: any;
  oldValue: any;

  getIDs(): object[];
  apply(rule: any): any;
}

export class BatchEdit implements batchEdit {
  id: object[];
  field: string;
  newValue: any;
  oldValue: any;

  constructor(id_list: object[], field: string, newValue: any) {
    this.id = id_list;
    this.field = field;
    this.newValue = newValue;
  }
  getIDs(): object[] {
    return this.id;
  }

  contains(id: object): boolean {
    return this.id.some((item: object) => _.isEqual(item, id));
  }

  apply(rule: any) {
    rule[this.field] = this.newValue;
    return rule;
  }
}

export class SingleEdit implements singleEdit {
  id: object;
  field: string;
  newValue: any;
  oldValue: any;
  insertion_id: number | undefined = undefined;

  constructor(
    id_list: object,
    field: string,
    newValue: any,
    oldValue: any,
    insertion_id: number | undefined = undefined,
  ) {
    this.id = id_list;
    this.field = field;
    this.newValue = newValue;
    this.oldValue = oldValue;
    this.insertion_id = insertion_id;
  }

  getIDs(): object[] {
    return [this.id];
  }

  contains(id: object): boolean {
    return _.isEqual(this.id, id);
  }

  apply(rule: any) {
    rule[this.field] = this.newValue;
    return rule;
  }
}

export class UndoQueue {
  private items: baseEdit[] = [];

  public empty(): void {
    this.items = [];
  }
  public getItems(): baseEdit[] {
    return this.items;
  }

  public enqueue(item: baseEdit): void {
    this.items.push(item);
  }

  public dequeue(): baseEdit | undefined {
    return this.items.pop();
  }

  public isEmpty(): boolean {
    return this.items.length === 0;
  }

  public size(): number {
    return this.items.length;
  }

  public peek(): baseEdit | undefined {
    return this.items[0];
  }

  getUniqueChangedRecords() {
    let updates = Object.values(this.getItems());
    let unique_ids = Array.from(
      new Set(
        updates
          .map((update: any) => {
            console.log("calculating, ", update.getIDs());
            return update.getIDs();
          })
          .flat(),
      ),
    );
    console.log("final: ", unique_ids);
    return unique_ids.length;
  }

  accountInfo: any;
  accountService: any;

  constructor() {
    const authService = inject(AuthService);
    this.accountInfo = authService.getCurrentUser();
    this.accountService = inject(AccountService);
  }

  convertChangesToActivity(): activity[] {
    let updates = Object.values(this.getItems());
    console.log("account info = ", this.accountInfo);

    return updates.map((update: any) => {
      return {
        type: "edit",
        column_name: update.field,
        new_value: update.newValue,
        old_value: update.oldValue,
        updated_by: this.accountInfo["account"]["full_name"],
        created_by_full_name: this.accountInfo["account"]["full_name"],
        created_at: new Date().toDateString(),
        approved_by: null,
        rejected_by: null,
        update_time: new Date().toDateString(),
        approve_time: null,
        rejection_time: null,
        created_by_profile_picture: this.accountService.profile_picture_link,
        status: "pending",
      };
    });
  }

  compileInsertions() {
    let insertionChanges = this.getItems().filter(
      (edit: any) => edit.insertion_id !== undefined,
    );
    let insertionGroupedById = {} as Record<number, any[]>;

    insertionChanges.map((change: any) => {
      if (
        !Object.keys(insertionGroupedById).includes(
          change.insertion_id.toString(),
        )
      ) {
        insertionGroupedById[change.insertion_id] = [];
      }
      insertionGroupedById[change.insertion_id].push(change);
    });

    let compiledInsertions = {} as Record<string, any>;
    Object.keys(insertionGroupedById).map((insertion_id: string) => {
      let actualInsertionId = parseInt(insertion_id);
      const insertionGroup = insertionGroupedById[actualInsertionId];

      let reversed = insertionGroup.reverse();
      let compiledInsert = {} as Record<string, any>;

      insertionGroup.map((insertionChange: any) => {
        compiledInsert[insertionChange.field] = insertionChange.newValue;
      });

      compiledInsertions[insertion_id] = compiledInsert;
    });

    console.log("compiled insertions ", compiledInsertions);
  }

  convertChangesToBackendFormat(table_name: string) {
    this.compileInsertions();
    let updates = Object.values(this.getItems());
    let final_updates = updates.map((update: any) => ({
      new_value: update["newValue"],
      row_id: update["id"],
      column_name: update["field"],
      old_value: update["oldValue"],
      table_name: table_name,
    }));
    return final_updates;
  }
}

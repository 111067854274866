import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BRMColumn } from "../brm/columns";
import { ScrollingModule } from "@angular/cdk/scrolling";

@Component({
  selector: "app-mine-brmtable",
  templateUrl: "./mine-brmtable.component.html",
  styleUrls: ["./mine-brmtable.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MineBRMTableComponent implements OnInit {
  @Input()
  columns = [] as { visible: boolean; column: BRMColumn; width: number }[];
  @Input() data: any[] = [];
  @Input() isEditingEnabled = false;

  @Input() paginator = false;
  @Input() rows_per_page: number | undefined;

  @Input() approveChangesIsEnabled = false;
  @Output() filterReset: EventEmitter<any> = new EventEmitter();
  @Output() filterChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() ruleChanged: EventEmitter<any> = new EventEmitter();

  @Input() primary_key = [] as string[];
  @Input() changeLog: any = {};

  allSelection = false;

  filterChanged(event: any, column: any) {
    alert("here");
    this.filterChangedEvent.emit({ event, column });
  }

  sorterChanged(event: any, column: any) {}

  currentTableMode() {
    return "edit";
  }

  allRowSelectionChanged(event: any) {}

  current_page = 1;
  paginator_pages_per_slice = 5;

  ngOnInit() {}

  all_rows_selected = true;

  //TODO move to computed signal
  currentPageData() {
    if (this.paginator && this.rows_per_page) {
      return this.data.slice(
        (this.current_page - 1) * this.rows_per_page,
        this.current_page * this.rows_per_page,
      );
    }
    return this.data;
  }

  validateInsert(rule: any) {
    if (rule["metadata"] === undefined) return true;
    return Object.keys(rule)
      .map((key: string) => {
        return rule[key] !== "";
      })
      .some((state: boolean) => state);
  }

  getVisibleHeaders() {
    return this.columns.filter((header: any) => header.visible);
  }

  trackByMolecule(_index: number, row: { molecule: string }) {
    return row.molecule;
  }

  newChange(changeInfo: any) {
    this.ruleChanged.emit(changeInfo);
  }

  goToFirst() {
    this.current_page = 1;
  }

  goToLast() {
    const lastPage = this.pages().pop();
    if (lastPage) this.current_page = lastPage.number;
  }

  nextPage() {
    if (this.current_page < this.numberOfPages()) this.current_page++;
  }

  previousPage() {
    if (this.current_page > 1) this.current_page--;
  }

  numberOfPages() {
    if (this.rows_per_page)
      return Math.ceil(this.data.length / this.rows_per_page);
    return 0;
  }

  pages() {
    if (this.rows_per_page) {
      const numberOfPages = this.numberOfPages();
      console.log("number of pages is ", numberOfPages);

      let pages = createArray(numberOfPages);

      let config = this.pageSlice(
        this.current_page,
        this.paginator_pages_per_slice,
      );

      console.log("config is", config);

      let result = pages.map((page_number: number) => {
        return {
          number: page_number,
          visible: config.start <= page_number && page_number <= config.end,
        };
      });

      console.log("result is ", result);
      return result;
    }
    return [];
  }

  pageSlice(page_number: number, pages_per_page: number) {
    const config = {
      start: Math.floor(page_number / pages_per_page) * pages_per_page,
    } as any;
    config.end = config.start + pages_per_page;
    return config;
  }

  navigateToPage(page_number: number) {
    this.current_page = page_number;
  }
}

function createArray(N: number) {
  const array = [];
  for (let i = 1; i <= N; i++) {
    array.push(i);
  }
  return array;
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TreeNode } from "primeng/api";
import {
  dataGroup,
  Column,
  isDataGroup,
  wrapArrayInTreeNode,
} from "./data-group";

@Component({
  selector: "app-mine-table",
  templateUrl: "./mine-table.component.html",
  styleUrls: ["./mine-table.component.scss"],
})
export class MineTableComponent {
  @Input() data: any[] | undefined = undefined;
  @Input() treeData: TreeNode<any>[] | undefined = undefined;

  @Input() files: TreeNode<any>[] | undefined;
  @Input() headers: any[] = [];
  @Input() isKPITable = false;

  @Input() selectionPredicate: ((rowData: any) => boolean) | undefined =
    undefined;

  @Output() rowClicked = new EventEmitter<any>();
  @Output() rowMouseEnter = new EventEmitter<any>();
  @Output() rowMouseLeave = new EventEmitter<any>();

  @Input()
  useIdForGroup = true;

  getHeaders() {
    return this.headers;
  }

  isTreeData() {
    return this.treeData != undefined;
  }

  getArrayData(): any[] {
    if (Array.isArray(this.data)) return this.data;
    else return [];
  }

  isArrayData() {
    return this.data !== undefined;
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  getColumns(): Column[] {
    return this.headers;
  }

  isEmpty() {
    return this.data === undefined || this.data.length === 0;
  }

  internalRowClicked(row: any) {
    this.rowClicked.emit(row);
  }

  internalRowMouseEnter(row: any) {
    this.rowMouseEnter.emit(row);
  }

  internalRowMouseLeave() {
    this.rowMouseLeave.emit();
  }

  formatIfNumber(value: string) {
    const int = parseInt(value);
    if (!isNaN(int)) {
      return formatNumber(int);
    }
    return value;
  }
}

export function formatNumber(num: number) {
  const numStr = num.toString();
  let formattedNum = "";
  let count = 0;

  for (let i = numStr.length - 1; i >= 0; i--) {
    formattedNum = numStr[i] + formattedNum;
    count++;
    if (count % 3 === 0 && i !== 0) {
      formattedNum = " " + formattedNum;
    }
  }

  return formattedNum;
}

import { DragStartDelay } from "@angular/cdk/drag-drop";
import { GeneratePreSignedURLForFileUploadService } from "./s3-upload";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { ToastService } from "src/app/core/services/toast.service";
import { OnInit } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";

import { Subscription } from "rxjs";

@Component({
  selector: "upload-control",
  templateUrl: "./upload-component.component.html",
  styleUrls: ["./upload-component.component.scss"],
  providers: [MessageService],
})
export class UploadComponentComponent implements OnInit {
  @Output() filesChanged = new EventEmitter<File | null>();
  @Input() mode: string = "data";
  @Input() fileName: string = "";
  @ViewChild("fileUpload") fileUpload: any;

  accept: string = "";
  indicateProgress = true;

  allowed_mime_types =
    this.mode === "data" ? allowed_data_mime_types : allowed_profile_picture;
  maxSize = this.mode === "data" ? data_max_size : profile_picture_max_size;

  selectedFile: File | null = null;

  is_error = false;

  constructor(
    private messageService: MessageService,
    private toastService: ToastService,
    private signedUrlGenerator: GeneratePreSignedURLForFileUploadService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.accept = this.mode === "data" ? data_accept : image_accept;
  }

  cancelUpload() {
    if (this.currentUpload) {
      this.currentUpload.unsubscribe();
      this.upload_percentage = 0;
    }
  }
  currentUpload: Subscription | null = null;

  upload_percentage = 0;

  uploadToS3(file: File, onComplete: () => void) {
    if (!this.is_error)
      this.signedUrlGenerator
        .getSignedURL(this.fileName !== "" ? this.fileName : "raw")
        .subscribe((res: string) => {
          console.log("the url is ", res);
          const url = res;

          this.currentUpload = this.http
            .put(url, file, {
              reportProgress: true,
              observe: "events",
            })

            .subscribe((event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                console.log("progress ", event.loaded / event.total);
                const ratio = event.loaded / event.total;
                this.upload_percentage = ratio;
              }
              if (event.type === HttpEventType.Response) {
                this.currentUpload = null;
                onComplete();
              }
            });
        });
  }

  showProgression = false;

  newFileSelection(files: File[]) {
    const selection = files[0];

    if (selection.size > this.maxSize) {
      this.toastService.addToast({
        severity: "error",
        summary: "Error",
        detail:
          "File exceed the size limitation of 3GB.  Please upload a smaller file, or <a href='https://mangabey.io/' target='_blank'>contact us.</a>",
      });

      this.is_error = true;
    }

    if (this.mode === "data") {
      const selectedFile = files[0];
      //this.filesChanged.emit(files[0]);
      this.showProgression = true;
      this.uploadToS3(selectedFile, () => {
        this.selectedFile = selectedFile;
        this.showProgression = false;
      });
    } else {
      this.selectedFile = files[0];
      this.filesChanged.emit(files[0]);
    }
  }

  getUploadLabel() {
    if (this.mode === "data") return "Click to Upload";
    else return "Click to upload a new picture";
  }

  getSizeNotice() {
    if (this.mode === "data") return "Max. File Size: 3GB";
    else return "SVG, PNG, JPG (max. 800x800px)";
  }

  fileDropped(event: DragEvent) {
    event.preventDefault();

    if (
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      const droppedFile = event.dataTransfer.files[0];

      //Ignore files with wrong formats
      if (!this.allowed_mime_types.includes(droppedFile.type)) return;

      this.selectedFile = droppedFile;

      if (this.selectedFile.size > this.maxSize) {
        this.toastService.addToast({
          severity: "error",
          summary: "Error",
          detail:
            "Selected file exceeded maximum allowed size: please contact support!",
        });

        this.is_error = true;
      }
    }
  }

  fileDragged(event: any) {
    event.preventDefault();
  }

  FileDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  displayUploadDialog() {
    this.fileUpload.choose();
  }

  deleteFile() {
    //TODO support deletion in the context of an S3 upload
    this.fileUpload.clear();
    this.is_error = false;
    this.selectedFile = null;
    this.filesChanged.emit(null);
  }
}

const allowed_data_mime_types = [
  "application/vnd.ms-excel",
  "text/csv",
  "text/plain",
];

const allowed_profile_picture = [
  "application/vnd.ms-excel",
  "text/csv",
  "text/plain",
];

const data_max_size = 3 * 1024 * 1024 * 1024;
const profile_picture_max_size = 8 * 1024 * 1024;

const data_accept = ".xls,.csv,.txt";
const image_accept = "image/*";

<div id="dialog_wrapper">
  <p-confirmDialog key="confirm_batch_decision_dialog">
    <ng-template pTemplate="header">
      <div>
        <h2>{{ this.getDialogHeader() }}</h2>
      </div>
      <img src="assets/icons/exit.svg" (click)="closeDialog()" />
    </ng-template>
    <ng-template pTemplate="message">
      <div
        id="content"
        *ngIf="this.isConfirmed !== confirmationState.InProgressConfirmation"
        style="width: 400px"
      >
        <img [src]="this.getIcon()" alt="" />
        <div id="message">
          <h2>{{ this.getMessageHeader() }}</h2>
          <p>{{ this.getMessage() }}</p>
        </div>
      </div>
      <div
        id="confirmation_progress"
        *ngIf="this.isConfirmed === confirmationState.InProgressConfirmation"
        style="
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <p-progressSpinner></p-progressSpinner>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        class="white_button"
        (onClick)="closeDialog()"
        *ngIf="this.isConfirmed === confirmationState.WaitingForConfirmation"
        >Cancel</p-button
      >
      <p-button
        id="confirm_button"
        (onClick)="confirm()"
        *ngIf="this.isConfirmed === confirmationState.WaitingForConfirmation"
      >
        Confirm
      </p-button>
      <p-button
        id="confirm_button"
        (onClick)="closeDialog()"
        *ngIf="this.isConfirmed === confirmationState.DoneConfirmation"
      >
        Done
      </p-button>
    </ng-template>
  </p-confirmDialog>
</div>

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";

import { environment } from "src/environments/environment";
import { Observable, map, catchError, throwError, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class AccountService {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  account: any = null;
  constructor(private http: HttpClient) {}
  // User Account
  getMyAccount(): Observable<any> {
    if (this.account !== null) {
      const image_link = this.account["account"]["profile_picture"];

      this.http
        .get<HttpResponse<any>>(image_link)
        .subscribe((res: HttpResponse<any>) => {
          console.log("status is ", res.status);
        });
      return of(this.account);
    }

    let api = `${environment.apiUrl}/account/my`;

    return this.http.get(api, { headers: this.headers });
  }

  profile_picture_link: string | undefined;

  getProfilePictureLink() {
    const link = `${environment.apiUrl}/account/get_profile_picture/`;

    this.http
      .get(link, { headers: this.headers, responseType: "text" })
      .subscribe((res: any) => {
        this.profile_picture_link = res;
      });
  }

  updateProfilePicture(newPicture: File) {
    const link = `${environment.apiUrl}/account/update_profile_picture/`;

    const formData = new FormData();
    formData.append("profile_picture", newPicture);

    return this.http.post(link, formData, {
      headers: {},
      responseType: "text",
    });
  }

  getProfilePictureLinkObservable() {
    const link = `${environment.apiUrl}/account/get_profile_picture/`;

    return this.http
      .get(link, { headers: this.headers, responseType: "text" })
      .pipe(
        map((res: any) => {
          this.profile_picture_link = res;
        }),
      );
  }

  skipOnBoarding(): Observable<any> {
    let api = `${environment.apiUrl}/account/skip_onboarding/`;

    return this.http.post(api, { headers: this.headers });
  }

  sendVerificationEmail() {
    let api = `${environment.apiUrl}/account/forget_password/`;

    return this.http.post(api, { headers: this.headers });
  }

  // generate jwt
  getJwt(ctx: number): Observable<any> {
    let api = `${environment.apiUrl}/account/generate-jwt/${ctx}`;
    return this.http
      .get(api, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = {};
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = {
        code: error.status,
        message: error.error,
      };
    }
    return throwError(msg);
  }
}

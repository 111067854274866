import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-upload-progress-indication",
  templateUrl: "./upload-progress-indication.component.html",
  styleUrls: ["./upload-progress-indication.component.scss"],
})
export class UploadProgressIndicationComponent implements OnInit {
  @Input() completion_ratio: number = 0;
  @Output() uploadIsCancelled: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {}

  getNoticeMessage() {
    return this.completion_ratio < 1
      ? "Loading File ..."
      : "Upload is Completed!";
  }
  completionWidth() {
    return (this.completion_ratio * 220).toFixed(0) + "px";
  }

  cancelUpload() {
    this.uploadIsCancelled.emit();
  }
}

<div class="accordion_wrapper">
    <div
        class="header_wrapper"
        [ngClass]="{ lower_margin: this.expanded }"
        (click)="this.toggle()"
    >
        <div class="right_side">
            <p>{{ this.getBatchLabel(update) }}</p>
            <p class="number_of_datasets">
                {{ this.update.number_of_datasets }} datasets
            </p>
        </div>
        <div class="left_side">
            <img
                [src]="this.getIcon(update)"
                style="margin-right: -16px; height: 14px; width: 14px"
            />
            <p
                [ngStyle]="{ color: this.getColor(update) }"
                style="white-space: nowrap"
            >
                {{ this.getDate(update) }}
            </p>
            <div class="expand_icon">
                <img [src]="this.getTogglerIcon()" />
            </div>
        </div>
    </div>
    <div class="content_wrapper" *ngIf="this.expanded">
        <ng-container
            [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{ $implicit: 4 }"
        ></ng-container>
    </div>
</div>

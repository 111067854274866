import { Component, signal } from "@angular/core";

@Component({
  selector: "app-mine-paginator",
  templateUrl: "./mine-paginatior.component.html",
  styleUrls: ["./mine-paginatior.component.scss"],
})
export class MinePaginatiorComponent {
  current_page = 1;
  rows_per_page = 10;
  data_length = 1000;
  paginator_pages_per_slice = 6;

  pageSlice(page_number: number, pages_per_page: number) {
    const config = {
      start: Math.floor(page_number / pages_per_page) * pages_per_page,
    } as any;
    config.end = config.start + pages_per_page;
    console.log("slice called page_number = ", page_number, config);
    return config;
  }

  navigateToPage(num: number) {
    this.current_page = num;
  }

  pages() {
    if (this.rows_per_page) {
      const numberOfPages = this.numberOfPages();
      console.log("number of pages is ", numberOfPages);

      let pages = createArray(numberOfPages);

      let config = this.pageSlice(
        this.current_page,
        this.paginator_pages_per_slice,
      );

      console.log("config is", config);

      let visibles = getFirstNItemsFromIndex(
        pages,
        this.paginator_pages_per_slice,
        config.start,
      );

      let result = pages.map((page_number: number, index: number) => {
        return {
          number: page_number,
          caption: page_number,
          visible: visibles.includes(page_number),
        };
      });

      console.log("result is ", result);
      return result;
    }
    return [];
  }
  numberOfPages() {
    if (this.rows_per_page)
      return Math.ceil(this.data_length / this.rows_per_page);
    return 0;
  }

  goToFirst() {
    this.current_page = 1;
  }

  goToLast() {
    const lastPage = this.pages().pop();
    if (lastPage) this.current_page = lastPage.number;
  }

  nextPage() {
    if (this.current_page < this.numberOfPages()) this.current_page++;
  }

  previousPage() {
    if (this.current_page > 1) this.current_page--;
  }
}

function createArray(N: number) {
  const array = [];
  for (let i = 1; i <= N; i++) {
    array.push(i);
  }
  return array;
}

function getFirstNItemsFromIndex(array: any[], N: number, I: number) {
  // Validate input parameters
  if (!Array.isArray(array) || N < 0 || I < 0 || I >= array.length) {
    return [];
  }

  // Calculate the end index, ensuring it doesn't exceed the array length
  const endIndex = Math.min(I + N, array.length);

  // Extract the desired slice of the array
  return array.slice(I, endIndex);
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Signal,
  signal,
  WritableSignal,
  ChangeDetectionStrategy,
  computed,
} from "@angular/core";
import {
  BRMColumn,
  ListBRMColumn,
  StringBRMColumn,
  brmColumnType,
} from "../brm/columns";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { SingleEdit } from "../brm/undoQueue";
import { capitalizeFirstLetter } from "../core/models/helpers";
@Component({
  selector: "app-brm-cell-editor",
  templateUrl: "./brm-cell-editor.component.html",
  styleUrls: ["./brm-cell-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrmCellEditorComponent {
  @Input() editingState = false;
  @Input() column: BRMColumn = new StringBRMColumn("recode");
  _displayValue: WritableSignal<any> = signal({});

  @Input() set displayValue(value: any) {
    console.log("I am called!");
    this._displayValue.update((old_value: any) => value);
  }

  @Input() primary_key: any;
  @Input() width: number = 100;
  @Output() newChange = new EventEmitter<any>();
  _check_validity = signal(false);

  @Input() set check_validity(new_check_validity: boolean) {
    this._check_validity.set(new_check_validity);
  }

  //Used for insertion rows
  isValid = computed(() => {
    if (this.check_validity) {
      console.log("checking ...");
      this._displayValue() !== null;
    }

    return true;
  });

  brmColumnType = brmColumnType;
  date: Date | undefined;

  ngOnInit() {
    if (this.column.type === brmColumnType.dateTime) {
      this.date = new Date(this.getDisplayValue());
    }

    if (this.column.field === "category_master") {
      console.log("it's cat_master and the width is ", this.width);
    }
  }

  capitalize(text: string | undefined) {
    if (text) return capitalizeFirstLetter(text);
    else return capitalizeFirstLetter("");
  }

  getCellKeyValue() {
    console.log("the key is ", this.primary_key);
    const key_value = {} as Record<string, any>;
    for (const key of this.primary_key) {
      key_value[key] = this._displayValue()[key];
    }

    console.log("key value is", key_value);
    return key_value;
  }

  change(event: any | CheckboxChangeEvent) {
    console.log("new change :", event);
    const oldValue = this._displayValue()[this.column.getField()];
    let insertion_id = undefined;
    if (this._displayValue()["metadata"]) {
      insertion_id = this._displayValue()["metadata"]["insertion_id"];
    }

    let id = this.getCellKeyValue();

    if ("target" in event) {
      let newValue = (event.target as HTMLInputElement).value;
      if (newValue === undefined) return;
      if (oldValue === newValue) return;
      this.newChange.emit(
        new SingleEdit(
          id,
          this.column.field,
          (event.target as HTMLInputElement).value,
          this._displayValue()["original_" + this.column.getField()],
          insertion_id,
        ),
      );
    } else {
      if (this.column.type === brmColumnType.boolean) {
        if (event.checked === undefined) return;
        this.newChange.emit(
          new SingleEdit(
            id,
            this.column.field,
            event.checked,
            this._displayValue()["original_" + this.column.getField()],
            insertion_id,
          ),
        );
      } else {
        //@TODO assumes that it's a list column (but make sure it can't be a number field => make sure that number fields are not editable)
        if (event.value === undefined) return;
        this.newChange.emit(
          new SingleEdit(
            id,
            this.column.field,
            event.value,
            this._displayValue()["original_" + this.column.getField()],
            insertion_id,
          ),
        );
      }
    }
  }

  getDisplayValue() {
    if (this.column.type === brmColumnType.boolean) {
      console.log(this._displayValue(), this.column.getField());
      return this._displayValue()[this.column.getField()];
    } else if (this.column.type === brmColumnType.dateTime) {
      return new Date(this._displayValue()[this.column.getField()]);
    } else if (this.column.type === brmColumnType.list) {
      return (
        this._displayValue()[this.column.getField()] as string
      ).toLowerCase();
    }
    return this._displayValue()[this.column.getField()];
  }

  getListOptions() {
    if (this.column.type === brmColumnType.list) {
      const listColumn = this.column as ListBRMColumn;
      return listColumn.getListItems();
    }

    return [];
  }

  formatDate(date: Date) {
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  }

  jsonify(object: any) {
    return JSON.stringify(object);
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sourcesGranularityFilter",
})
export class SourcesGranularityFilterPipe implements PipeTransform {
  transform(sources: any[], selectedGranularities: string[]): any[] {
    if (
      selectedGranularities.length === 1 &&
      selectedGranularities[0] === "all"
    ) {
      return sources;
    }

    console.log("granularity called!");
    return sources.filter(
      (source) =>
        source.granularity &&
        selectedGranularities.includes(source.granularity),
    );
  }
}

import { Component, Input } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { BRMServiceService } from "../core/services/brmservice.service";
import { Observable, ObservableInput, timer, zip } from "rxjs";

enum ConfirmationState {
  WaitingForConfirmation,
  InProgressConfirmation,
  DoneConfirmation,
}

@Component({
  selector: "app-confirm-batch-decision",
  templateUrl: "./confirm-batch-decision.component.html",
  styleUrls: ["./confirm-batch-decision.component.scss"],
})
export class ConfirmBatchDecisionComponent {
  @Input() decision_is_approval: boolean = false;
  confirmationState = ConfirmationState;
  isConfirmed = ConfirmationState.WaitingForConfirmation;

  constructor(
    private confirmationService: ConfirmationService,
    private brmService: BRMServiceService
  ) {}

  getDialogHeader() {
    return this.decision_is_approval ? "Approve Selected" : "Reject Selected";
  }

  getMessageHeader() {
    return this.isConfirmed ? "Congratulations!" : "Are you sure?";
  }

  confirm() {
    this.decideBatch();
  }

  getMessage() {
    if (this.decision_is_approval) {
      return "This action will accept all selected updates at same time. ";
    } else return "This action will reject all selected updates at same time. ";
  }

  getConfirmationMessage() {
    if (this.decision_is_approval) {
      return "You have successfully approved all selected changes.";
    } else return "You have successfully rejected all selected changes.";
  }

  getIcon() {
    const iconName = this.decision_is_approval ? "accept.png" : "reject.png";

    return `assets/icons/batch_decision/${iconName}`;
  }

  @Input()
  batchDecisionsIDs: any[] | undefined;

  decideBatch() {
    this.isConfirmed = ConfirmationState.InProgressConfirmation;
    if (!this.batchDecisionsIDs) return;

    const toSubmit = this.batchDecisionsIDs;
    const mode = this.decision_is_approval ? "approve" : "reject";
    zip(
      mode === "reject"
        ? toSubmit.map((change_id: any) =>
            this.brmService.rejectChanges(change_id)
          )
        : toSubmit.map((change_id: any) =>
            this.brmService.approveChanges(change_id)
          )
    ).subscribe(() => {
      this.isConfirmed = ConfirmationState.DoneConfirmation;
    });
  }

  closeDialog() {
    if (this.isConfirmed !== ConfirmationState.InProgressConfirmation) {
      this.isConfirmed = this.confirmationState.WaitingForConfirmation;
      this.confirmationService.close();
    }
  }
}

function delay(seconds: number): ObservableInput<void> {
  return timer(seconds * 1000)
    .toPromise()
    .then(() => {});
}

import { Component, OnInit } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { AccountService } from "../core/services/account.service";
import { AuthService } from "../core/services/auth.service";
import { getUserRole } from "../dashboard-layout/dashboard-layout.component";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  selected_sub_menu = "profile";
  is_edit_mode = false;
  sub_menus = [
    {
      id: "profile",
      title: "Profile",
      description: "Personal details, profile picture, password",
      icon: "profile.png",
    },
    {
      id: "notifications",
      title: "Notifications",
      description: "Communication preferences",
      icon: "notification.png",
    },
    {
      id: "billing",
      title: "Billing",
      description: "Billing plans, subscriptions, invoices",
      icon: "bill.png",
    },
  ];

  notifications_settings = [
    {
      id: "updates_stepper",
      label: "Updates in stepper",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: "new_data_source",
      label: "New Data Source",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: "brm_edits",
      label: "Edits in BRM table",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];
  constructor(
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private accountService: AccountService,
  ) {}
  currentUser = {} as any;
  profile_picture: string = "";

  ngOnInit(): void {
    this.loadUserSettings();
  }

  loadUserSettings(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.authService.refreshAccountInfo().subscribe(() => null);
    this.accountService.getProfilePictureLinkObservable().subscribe((res) => {
      if (this.accountService.profile_picture_link)
        this.profile_picture = this.accountService.profile_picture_link;
      console.log("pic: profile pic is ", this.profile_picture);
    });
  }

  current_image_upload: File | null = null;

  newProfilePictureUpload(event: any) {
    this.current_image_upload = event;
    console.log("upload info ", event);
  }

  save() {
    console.log("saving ???", this.current_image_upload);
    if (this.current_image_upload) {
      this.accountService
        .updateProfilePicture(this.current_image_upload)
        .subscribe((res) => {
          console.log("picture updated!");
          this.loadUserSettings();
        });
    }
  }

  formatRole() {
    return getUserRole(this.currentUser.role);
  }

  changeSelectedSubMenu(id: string) {
    this.selected_sub_menu = id;
  }

  passwordPoints() {
    return Array(8).fill(0);
  }

  enableEditMode() {
    this.is_edit_mode = true;
  }

  cancelChanges() {
    this.is_edit_mode = false;
  }

  openPasswordChangeConfirmation() {
    this.confirmationService.confirm({
      header: "Confirmation",
      key: "confirm_password_change",
      message: "Please confirm to proceed moving forward.",
      acceptIcon: "pi pi-check mr-2",
      rejectIcon: "pi pi-times mr-2",
      rejectButtonStyleClass: "p-button-sm",
      acceptButtonStyleClass: "p-button-outlined p-button-sm",
      accept: () => {
        alert("yes");
      },
      reject: () => {
        alert("yes");
      },
    });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { SourceService } from "../core/services/source.service";
import { Source } from "../core/models/source";
import {
  DataSourceFormComponent,
  unwrap_other,
} from "../data-source-form/data-source-form.component";
import { convertUTCToLocal } from "../core/utils/dateTime";

@Component({
  selector: "data-source-dialog",
  templateUrl: "./data-source-dialog.component.html",
  styleUrls: ["./data-source-dialog.component.scss"],
})
export class DataSourceDialogComponent {
  source: Source | null = null;
  @ViewChild("form") form: DataSourceFormComponent | null = null;
  @Output() sourceUpdated = new EventEmitter();

  constructor(
    private confirmationDialog: ConfirmationService,
    private sourceSerivce: SourceService,
  ) {}

  sourceInformationUpdated(source: Source) {
    this.source = { id: this.source?.id, ...source };
    console.log("it changes it's", this.source);
  }

  submitData() {
    if (this.source) {
      this.source = fixSourceObjects(this.source);

      if (!this.source) return;

      this.source.data_upload = undefined;
      if (this.source.current_update)
        this.source.current_update = this.convertToDjangoFormat(
          new Date(this.source.current_update),
        );
      else this.source.current_update = undefined;

      if (this.source.end_period)
        this.source.end_period = this.convertToDjangoFormat(
          new Date(this.source.end_period),
        );

      if (this.source.next_update)
        this.source.next_update = this.convertToDjangoFormat(
          new Date(this.source.next_update),
        );
      else this.source.next_update = undefined;

      if (this.source.start_date)
        this.source.start_date = this.convertToDjangoFormat(
          new Date(this.source.start_date),
        );
      else this.source.start_date = undefined;

      this.sourceSerivce.updateSource(this.source).subscribe((res) => {
        this.sourceUpdated.emit();
        this.closeDialog();
      });
    }
  }

  closeDialog() {
    this.confirmationDialog.close();
  }

  convertToDjangoFormat(date: Date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00`;
  }

  getSourceCopy() {
    return { ...this.source };
  }
}

export function fixSourceObjects(initialSource: any) {
  const source = initialSource;
  const ignoreOthers = true;

  source.data_vendor = unwrap_other(
    initialSource.data_vendor as any,
    ignoreOthers,
  );
  source.scope_of_subscription = unwrap_other(
    initialSource.scope_of_subscription as any,
    false,
  );
  if (source.formulation_class_type)
    source.formulation_class_type = unwrap_other(
      initialSource.formulation_class_type as any,
      ignoreOthers,
    );
  if (source.therapy_class_type)
    source.therapy_class_type = unwrap_other(
      initialSource.therapy_class_type as any,
      ignoreOthers,
    );
  source.update_frequency = unwrap_other(
    initialSource.update_frequency as any,
    ignoreOthers,
  );
  return source;
}

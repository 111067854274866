import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-place-holder",
  templateUrl: "./place-holder.component.html",
  styleUrls: ["./place-holder.component.scss"],
})
export class PlaceHolderComponent implements OnInit {
  myForm: FormGroup;
  scopes_of_subscription = [{ value: "a", label: "a" }];

  constructor(private fb: FormBuilder) {
    this.myForm = this.fb.group({
      scope_of_subscription: [
        {
          is_other: true,
          value: ["dddd"],
        },
      ],
    });
    this.myForm.controls["scope_of_subscription"].setValue({
      is_other: true,
      value: ["dddd"],
    });
  }

  ngOnInit() {}

  submit() {
    alert(JSON.stringify(this.myForm.value));
  }
}

<div id="wrapper">
    <div id="spinner_wrapper" *ngIf="!this.loadingIsComplete">
        <p-progressSpinner />
    </div>
    <div class="page_header">
        <app-sub-menu></app-sub-menu>
        <h1 style="margin-top: 16px; margin-bottom: 16px">Dashboard</h1>

        <div id="filters">
            <app-quality-center-filters
                (stepSelectionChangedEvent)="this.stepSelectionChanged($event)"
            ></app-quality-center-filters>
        </div>
    </div>
    <div *ngIf="this.selected_steps.length === 0" class="no_data_message">
        <p>No Data available for your selection.</p>
    </div>
    <div id="content">
        <div style="margin-bottom: 20px" *ngIf="this.selected_steps.length > 0">
            <app-mine-table
                [treeData]="
                    this.stepKPIs | KPIsStepsFilter: this.selected_steps
                "
                [headers]="this.stepKPIsHeaders"
                [isKPITable]="true"
                (rowClicked)="this.tableRowClicked($event)"
                [selectionPredicate]="this.selectionPredicate"
                [useIdForGroup]="false"
                *ngIf="this.stepKPIs"
            ></app-mine-table>
        </div>
        <div *ngIf="this.selected_steps.length > 0">
            <app-attributes-charts
                [current_records_data]="
                    this.attribute_charts_values['current_records']
                "
                [unique_values_data]="
                    this.attribute_charts_values['unique_values']
                "
                [percent_blanks_data]="
                    this.attribute_charts_values['percent_blanks']
                "
                [field_name]="this.getCurrentSelectedField()"
            ></app-attributes-charts>
        </div>
    </div>
</div>

import { Component, OnInit } from "@angular/core";
import { TreeNode } from "primeng/api";
import {
  wrapArrayInTreeNode,
  wrapMultipleInTreeNode,
} from "../mine-table/data-group";
import { SourceService } from "../core/services/source.service";
import { capitalizeFirstLetter, steps_ordering } from "../core/models/helpers";
import { stepKPIsHeaders, last_n_batchs } from "../core/utils/KPIUtils";

@Component({
  selector: "app-quality-center-dashboard",
  templateUrl: "./quality-center-dashboard.component.html",
  styleUrls: ["./quality-center-dashboard.component.scss"],
})
export class QualityCenterDashboardComponent implements OnInit {
  selected_steps = [] as string[];

  stepSelectionChanged($event: any) {
    this.selected_steps = $event;
  }

  getCurrentSelectedField(): string {
    if (this.selected_path) {
      let lastItem = this.selected_path.split(".").pop();

      if (lastItem) {
        return lastItem
          .split("_")
          .map((chunk: string) => capitalizeFirstLetter(chunk))
          .join(" ");
      }
      return "";
    }
    return "";
  }

  selected_path: string | undefined;
  selectionPredicate = (rowData: any) => {
    if (this.selected_path && rowData["path"]) {
      return rowData["path"] === this.selected_path;
    }
    return false;
  };

  selectKPIPath(kpi_path: string) {
    this.selected_path = kpi_path;
    let KPIsHistory = this.KPIsByPath[kpi_path];

    console.log("current path info ,", this.KPIsByPath[kpi_path]);
    const batch_ids = this.KPIsByPath[kpi_path].map((kpi: any) => {
      return kpi["batch_id"];
    });

    KPIsHistory = KPIsHistory.sort((kpiA: any, kpiB: any) =>
      parseInt(kpiA["batch_id"]) > parseInt(kpiB["batch_id"]) ? 1 : -1,
    );
    const attributes = ["unique_values", "current_records", "percent_blanks"];
    const labelsAndData = {} as Record<
      string,
      { labels: string[]; data: any[] }
    >;

    attributes.map((attribute: string) => {
      labelsAndData[attribute] = { data: [], labels: [] };
      last_n_batchs(KPIsHistory, 6).map((KPI: any) => {
        labelsAndData[attribute].data.push(KPI[attribute]);
        labelsAndData[attribute].labels.push("Upd #" + KPI["batch_id"]);
      });
    });

    attributes.map((attribute: string) => {
      this.attribute_charts_values[attribute] = {
        ...this.attribute_charts_values[attribute],
        datasets: [
          {
            ...attribute_chart_data_point_template,
            data: labelsAndData[attribute].data,
          },
        ],
        labels: labelsAndData[attribute].labels,
      };
    });
    this.attribute_charts_values = { ...this.attribute_charts_values };
  }

  tableRowClicked($event: any) {
    if ($event["path"]) {
      const path = $event["path"];
      this.selectKPIPath(path);
    }
  }

  jsonify(o: any) {
    return JSON.stringify(o);
  }
  attribute_charts_values = {
    unique_values: {
      labels: [] as any[],
      datasets: [] as any[],
    },

    current_records: {
      labels: [] as any[],
      datasets: [] as any[],
    },

    percent_blanks: {
      labels: [] as any[],
      datasets: [] as any[],
    },
  } as Record<string, any>;

  labels: any[] = ["Jan 2024", "Feb 2024", "Mar 2024"];

  stepKPIsHeaders = stepKPIsHeaders;

  stepKPIs: TreeNode<any>[] | undefined;

  loadingIsComplete = false;

  constructor(
    private sourceService: SourceService,
    private sourceSerivce: SourceService,
  ) {}
  ngOnInit() {
    this.sourceService.getQCStats().subscribe((res: any) => {
      console.log("after removing duplicates ", res.length);
      res = get_unique_stats(res);
      console.log("after removing duplicates ", res.length);

      res = convertAllKPIsToIntegers(res);
      res = augmentKPIsWithPaths(res);

      this.KPIsByPath = this.groupKPIsByKPIPath(res);
      res = this.aggregateKPIs(res);
      let grouping = fixTrcTranscoding(groupBy(res, "batch_step"));
      console.log("aag =", this.aggregateKPIs(res));
      grouping = this.fixCleaning(grouping);

      const final_grouping: Record<string, Record<string, any>> = {};

      Object.entries(grouping).forEach(([key, value]) => {
        final_grouping[key] = groupBy(value, "field_name", true);
      });

      console.log("result3 = ", this.stepKPIs);
      console.log(
        "tree_data = ",
        this.createDataTreeFromKPIs(final_grouping["cleaning"], true),
      );

      this.stepKPIs = this.createDataTreeFromNestedKPIs(final_grouping, true);
      this.stepKPIs = this.orderSteps(this.stepKPIs);
      this.selectKPIPath("recoding.cso_product.product");
      console.log("step KPIs ", this.stepKPIs);

      this.loadingIsComplete = true;
    });
  }

  aggregateKPIs(KPIs: any[]): any[] {
    const groups = new Set<string>();
    KPIs.map((KPI: any) => groups.add(KPI.field_name));
    const final_KPIs = [] as any[];

    Array.from(groups).map((group: string) => {
      const groupKPIs = KPIs.filter((KPI: any) => KPI.field_name === group);
      const newKPI = combine(groupKPIs);
      final_KPIs.push(newKPI);
    });

    return final_KPIs;
  }

  KPIsByPath = {} as Record<string, any[]>;

  fixCleaning(KPIsByStep: any) {
    const cleaningKPIs = KPIsByStep["cleaning"];
    const newKPIs = [] as any[];

    if (Object.keys(KPIsByStep).includes("cleaning")) {
      console.log("cleaning is ", KPIsByStep["cleaning"]);

      const unique_fields = new Set<string>();

      cleaningKPIs.map((KPI: any) =>
        unique_fields.add(KPI["field_name"].split(".")[1]),
      );

      Array.from(unique_fields).map((unique_field: string) => {
        const field_values = cleaningKPIs.filter(
          (KPI: any) => KPI["field_name"].split(".")[1] === unique_field,
        );
        newKPIs.push({
          ...this.sumColumns(field_values),
          field_name: "Cleaned files." + unique_field,
        });
      });
    }

    KPIsByStep["cleaning"] = newKPIs;
    return KPIsByStep;
  }

  getKPIsByBatch(KPIs: any[]) {
    const unique_batch_ids = new Set<string>();
    KPIs.map((KPI: any) => unique_batch_ids.add(KPI["batch_id"]));

    const grouped = {} as Record<string, any[]>;
    Array.from(unique_batch_ids).map((batch_id: string) => {
      const currentKPIs = KPIs.filter(
        (KPI: any) => KPI["batch_id"] === batch_id,
      );
      if (currentKPIs) grouped[batch_id] = currentKPIs;
    });

    console.log("uniqs ", grouped);
  }

  groupKPIsByKPIPath(KPIs: any[]) {
    const unique_paths = new Set<string>();
    KPIs.map((KPI: any) =>
      unique_paths.add(KPI["batch_step"] + "." + KPI["field_name"]),
    );

    const grouped = {} as Record<string, any[]>;
    Array.from(unique_paths).map((batch_id: string) => {
      const currentKPIs = KPIs.filter(
        (KPI: any) => KPI["batch_step"] + "." + KPI["field_name"] === batch_id,
      );
      if (currentKPIs) grouped[batch_id] = currentKPIs;
    });
    return grouped;
  }

  orderSteps(stepsNodes: TreeNode[]) {
    const final_tree = [] as TreeNode[];

    steps_ordering.map((step_name: string) => {
      let stepKPIs = stepsNodes.find(
        (node: TreeNode) =>
          node.data["label"] === capitalizeFirstLetter(step_name),
      );
      console.log("found hree ", stepKPIs);
      if (stepKPIs) final_tree.push(stepKPIs);
    });

    return final_tree;
  }

  createDataTreeFromNestedKPIs(
    groupedKPIs: Record<string, Record<string, any[]>>,
    addSum = false,
  ) {
    const treeData: TreeNode = {
      data: {},
      children: [],
    };

    const final_nodes: TreeNode[] = [];

    Object.entries(groupedKPIs).forEach(([key, value]) => {
      let new_node = {
        data: {
          label: capitalizeFirstLetter(key),
        },
        children: this.createDataTreeFromKPIs(value, true),
      } as TreeNode;

      final_nodes.push(new_node);

      if (addSum) {
        let sum = columns.reduce((acc: Record<string, number>, key, index) => {
          if (new_node.children) {
            acc[key] = this.sum(
              new_node.children.map(
                (child: TreeNode) => child.data["sum"][key],
              ),
            );
          }
          return acc;
        }, {});
        new_node.data["sum"] = sum;
      }
    });
    return final_nodes;
  }

  sum = (numbers: number[]) => numbers.reduce((acc, val) => acc + val, 0);

  sumColumns = (values: any[]) => {
    return columns.reduce((acc: Record<string, number>, key, index) => {
      acc[key] = this.sum(values.map((value: any) => value[key]));
      return acc;
    }, {});
  };

  createDataTreeFromKPIs(groupedKPIs: Record<string, any[]>, addSum = false) {
    const treeData: TreeNode = {
      data: {},
      children: [],
    };

    const final_nodes: TreeNode[] = [];

    Object.entries(groupedKPIs).forEach(([key, value]) => {
      let new_node = {
        data: {
          label: capitalizeFirstLetter(key),
          sum: addSum ? {} : undefined,
        },
        children: value.map((kpi: any) => ({ data: { ...kpi } })),
      };

      if (addSum) {
        let sum = columns.reduce((acc: Record<string, number>, key, index) => {
          acc[key] = this.sum(
            new_node.children.map((child: TreeNode) => child.data[key]),
          );
          return acc;
        }, {});
        new_node.data["sum"] = sum;
      }

      final_nodes.push(new_node);
    });

    return final_nodes;
  }

  cleanKPIs(KPIs: any) {
    let keys = Object.keys(KPIs);
    let result = Object.values(KPIs[keys[0]]);
    result = result.map((value: any) => ({
      ...value,
      field_name: value.field_name.split(".")[1],
    }));
    console.log(result);

    return result;
  }

  generateRandomNumbers(n: number, min: number, max: number) {
    const numbers = [];

    for (let i = 0; i < n; i++) {
      // Generate random number between min and max (inclusive)
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      numbers.push(randomNumber);
    }
    return numbers;
  }
}

export function convertAllKPIsToIntegers(KPIs: any[]) {
  return KPIs.map((KPI: any) => {
    let new_kpi = KPI;
    columns.map((column: string) => {
      new_kpi[column] = Number.isNaN(parseInt(KPI[column]))
        ? KPI[column]
        : parseInt(KPI[column]);
    });
    return new_kpi;
  });
}

export function augmentKPIsWithPaths(res: any): any {
  return res.map((KPI: any) => ({
    ...KPI,
    path: KPI["batch_step"] + "." + KPI["field_name"],
  }));
}

export function fixTrcTranscoding(KPIsByStep: Record<string, any>) {
  if (Object.keys(KPIsByStep).includes("trc_pg")) {
    KPIsByStep["transcoding"] = KPIsByStep["trc_pg"];
    delete KPIsByStep["trc_pg"];
  }

  return KPIsByStep;
}

export function groupBy(
  data: any[],
  field_name: string,
  removeGroupName = false,
) {
  const uniqueGroups = new Set<string>();
  data.map((data_point: any) =>
    uniqueGroups.add(data_point[field_name].split(".")[0]),
  );
  console.log("unique_groups_group_by ", Array.from(uniqueGroups));
  const final_KPIS = {} as Record<string, any[]>;
  Array.from(uniqueGroups).map(
    (unique_group: string) =>
      (final_KPIS[unique_group] = data
        .filter(
          (data_point: any) =>
            data_point[field_name].split(".")[0] === unique_group,
        )
        .map((KPI: any) =>
          removeGroupName ? removeGroupNameFromKPI(KPI) : KPI,
        )),
  );
  console.log("final processing ", final_KPIS);
  return final_KPIS;
}

export function removeGroupNameFromKPI(KPI: any) {
  return { ...KPI, field_name: KPI.field_name.split(".")[1] };
}

export const attribute_chart_data_point_template = {
  fill: false,
  borderColor: "#35BA55",

  pointStyle: "circle",

  radius: 7,
  pointBackgroundColor: "white",
  pointBorderWidth: 1.5,

  hoverRadius: 7,
  pointHoverBackgroundColor: "white",
  pointHoverBorderWidth: 1.5,

  borderWidth: 1.5,
};

export const columns = [
  "current_records",
  "new_records",
  "percent_blanks",
  "percent_new_blanks",
  "unique_new_values",
  "unique_values",
] as string[];

function combine(KPIs: any[]) {
  if (KPIs.length === 0) return [];

  const newKPI = KPIs[0];

  //TODO: refactor to use reduce instead of map
  KPIs.map((KPI: any) => {
    columns.map((column: string) => {
      newKPI[column] = newKPI[column] + KPI[column];
    });
  });

  return newKPI;
}

function same_kpi(kpiOne: any, kpiTwo: any) {
  const keys = ["batch_id", "batch_step", "field_name"] as any;
  return keys
    .map((key: string) => kpiOne[key] === kpiTwo[key])
    .every((state: boolean) => state);
}

export function get_unique_stats(KPIs: any[]) {
  const final_kpis = [] as any[];

  KPIs.map((KPI: any) => {
    const found = final_kpis.find((final_kpi: any) => same_kpi(final_kpi, KPI));
    if (!found) final_kpis.push(KPI);
  });

  return final_kpis;
}

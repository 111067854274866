import * as AWS from "aws-sdk";
import { Injectable } from "@angular/core";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { HttpClient } from "@angular/common/http";
import { from } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GeneratePreSignedURLForFileUploadService {
  s3var: S3Client;

  constructor(private http: HttpClient) {
    const region = "eu-west-3";
    const identityPoolId = "eu-west-3:2cef7db9-ccea-4c83-a265-6c4504a58eb7";

    const userPollId = "51d9305e-d0a1-70ca-8d6e-1b6a0f6e596d";

    const client = new CognitoIdentityClient({ region: region });

    const credentials = fromCognitoIdentityPool({
      client,
      identityPoolId,
    });

    this.s3var = new S3Client({
      region,
      credentials,
    });
  }

  getSignedURL(key: string) {
    if (this.s3var) {
      key = "mine/aggregator/clean/input/" + key;
      const bucketName = "mangabey-dev";
      const contents = "Upload success!";
      const blob = new Blob([contents], { type: "text/plain" });
      let f = new File([blob], key);

      const params: any = new PutObjectCommand({
        Bucket: bucketName,
        Key: key,
      });

      return from(getSignedUrl(this.s3var, params));
    } else return from("");
  }
}

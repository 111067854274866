import * as moment from "moment";
//TODO: replace other constants in components to constants like these
export const KPIHeaders = [
  { header: "Current records", field: "current_records" },
  { header: "New Records", field: "new_records" },
  { header: "Unique Values", field: "unique_values" },
  { header: "Unique New Values", field: "unique_new_values" },
  { header: "Undefined Values (%)", field: "total_blank_values" },
  { header: "New Undefined Values (%)", field: "total_new_blank_values" },
];

export const stepKPIsHeaders = [
  { header: "Data", field: "field_name" },
  { header: "Current records", field: "current_records" },
  { header: "New Records", field: "new_records" },
  { header: "Unique Values", field: "unique_values" },
  { header: "Unique New Values", field: "unique_new_values" },
  { header: "Undefined Values (%)", field: "percent_blanks" },
  { header: "New Undefined Values (%)", field: "percent_new_blanks" },
];

export const summary_table_headers = [
  { name: "", key: "batch_step" },
  ...toKeyName(KPIHeaders),
];

export const attributes_information_headers = [
  { name: "Field", key: "field" },
  ...toKeyName([
    { header: "Current records", field: "current_records" },
    { header: "New Records", field: "new_records" },
    { header: "Unique Values", field: "unique_values" },
    { header: "Unique New Values", field: "unique_new_values" },
    { header: "Undefined Values (%)", field: "percent_blanks" },
    { header: "New Undefined Values (%)", field: "percent_new_blanks" },
  ]),
];

function toKeyName(arr: { header: string; field: string }[]) {
  return arr.map((item: any) => ({
    key: item.field,
    name: item.header,
  }));
}

export function last_n_batchs(
  KPIs: any[],
  n: number,
  dataIsByEndPeriod = false,
) {
  if (!dataIsByEndPeriod) {
    let batch_list = KPIs.map((KPI: any) => KPI.batch_id);
    console.log("updates => ", batch_list);
    batch_list = batch_list
      .map((batch_id: string) => parseInt(batch_id))
      .sort((a, b) => b - a)
      .slice(0, n);

    return KPIs.filter((KPI: any) =>
      batch_list.includes(parseInt(KPI.batch_id)),
    );
  } else {
    let final_KPIs = KPIs.map((KPI: any) => ({
      ...KPI,
      end_period: moment(KPI.end_period, "YYYYMM").toDate(),
    }));

    final_KPIs = final_KPIs
      .sort((a, b) => b.end_period.getTime() - a.end_period.getTime())
      .slice(0, n);

    return final_KPIs;
  }
}
